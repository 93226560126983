/**
 * @ngdoc Component
 * @name tallyfy.process.component.processEdit
 * @module tallyfy.process
 *
 * @description
 * A component to manage process edit view
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .component('processEdit', {
      templateUrl: 'app/modules/process/components/edit/process-edit.html',
      controller:
        /*@ngInject*/
        function (_, $scope, $stateParams, ProcessService, $timeout, Growl, blockUI, $log, UsersService, TagsService, $state, $filter, $q,
          StepService, $rootScope, translationHandler, koStepService, RunDetailService, $aside, $uibModalStack, TranslationService, $uibModal,
          FieldService, FavoriteService, Helper, ProcessModalService, store, TFY_EVENTS, moment, AuthPlan, BLUEPRINT_TYPE, DOMService, DOM,
          DateUtils, $analytics, $location, FolderService, UtilsService, CompactStepService, USER_STATE, $window) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('editProcess'),
            unregisterProcessHandler,
            unregisterProcessHandlerViaDrawer,
            modalInstance,
            moreDetailDrawerInstance,
            processName,
            deadlineUpdateEventHandler,
            showButonEventHandler,
            oldProcess,
            stepChangesTimeoutHandler,
            boxHeaderCheckingTimeoutHandler,
            processTypeWatcher,
            prerunWatcher,
            contentLanguageChangedHandler,
            unRegisteredPreferenceWatcherHandler,
            automationAddHandler,
            automationUpdateHandler,
            automationDeleteHandler,
            openAutomationPage,
            body = angular.element('body'),
            appBody = angular.element('.app-body'),
            windowElem = angular.element($window),
            mainSectionHeightHandler,
            rightPaneOpenHandler,
            rightPaneCloseHandler,
            froalaFullscreenHandler,
            processStepsUpdatedHandler,
            froalaHtmlHeightHandler,
            selectedItemWatcherHandler,
            folderSelectedWatcher,
            folderRemovedWatcher,
            fieldChangesWatcher;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.process = {};
          $ctrl.isLoading = false;
          $ctrl.highestDeadline = '';
          $ctrl.froalaTextShortenConfig = {};
          $ctrl.BP_VIEW = {
            READ: 'readBP',
            EDIT: 'editBP',
            AUTOMATIONS: 'automations'
          };
          $ctrl.metadata = {};
          $ctrl.stepDescribeForm = {};
          $ctrl.captureForm = {};
          $ctrl.assignForm = {};
          $ctrl.deadlineForm = {};
          $ctrl.logicForm = {};
          $ctrl.settingsForm = {};
          $ctrl.havePermissionToEdit = true;
          $ctrl.havePermissionToRead = true;
          $ctrl.havePermissionToLaunch = true;
          $ctrl.havePermissionToDuplicate = true;
          $ctrl.froalaViewBusy = true;
          $ctrl.stepCategories = StepService.getStepCategories();

          /**
           * public methods
           */
          $ctrl.onFavoriteClick = onFavoriteClick;
          $ctrl.saveProcess = saveProcess;
          $ctrl.checkProcessTitleChanged = checkProcessTitleChanged;
          $ctrl.startRun = startRun;
          /* $ctrl.updateStepCount = updateStepCount; */
          $ctrl.toggleBPEDetail = toggleBPEDetail;
          $ctrl.openDrawer = openDrawer;
          $ctrl.openEditRunNameModal = openEditRunNameModal;
          $ctrl.toggleProcessTab = toggleProcessTab;
          $ctrl.dirtyCheckHandler = dirtyCheckHandler;
          $ctrl.beforeRightPaneClose = beforeRightPaneClose;
          $ctrl.onTabChanged = onTabChanged;
          $ctrl.onItemSwitched = onItemSwitched;
          $ctrl.onRightPaneClosed = onRightPaneClosed;
          $ctrl.openPowerBuilder = openPowerBuilder;
          $ctrl.setTagTextColor = TagsService.isTagTextColor;
          $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
          $ctrl.isBPEDetailDrawerOpen = isBPEDetailDrawerOpen;
          $ctrl.duplicateBPE = ProcessService.showDuplicateTemplateModal;
          $ctrl.archiveProcess = archiveProcess;
          $ctrl.onIconSelected = onIconSelected;
          $ctrl.getLastSavedTime = getLastSavedTime;
          $ctrl.shareBPLink = shareBPLink;
          $ctrl.checkKOFormField = checkKOFormField;
          $ctrl.tocMenuItemClick = tocMenuItemClick;
          $ctrl.toggleTocView = toggleTocView;
          $ctrl.prerunValues = {};
          $ctrl.setBlueprintStatus = setBlueprintStatus;
          $ctrl.onEditPreviewChanged = onEditPreviewChanged;
          $ctrl.closePrintOptionsPane = closePrintOptionsPane;
          $ctrl.onPlayClick = onPlayClick;
          $ctrl.isLaunchBtnDisabled = isLaunchBtnDisabled;
          $ctrl.onBreadcrumbClick = onBreadcrumbClick;
          $ctrl.openAutomations = openAutomations;
          $ctrl.handleFolderDropdownClick = handleFolderDropdownClick;
          $ctrl.onCategoryChange = onCategoryChange;
          $ctrl.handleSettingsChange = handleSettingsChange;
          $ctrl.handleMoreChange = handleMoreChange;

          windowElem.on('resize', onWindowResize);

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            body.removeClass('compact-task-active');
            $ctrl.documentTranslateTo = TranslationService.getMyContentLanguage();
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            var view = _.get($state, 'params.view');
            $ctrl.activeTab = view ? (view === 'read' ? $ctrl.BP_VIEW.READ : (view === 'edit' ? $ctrl.BP_VIEW.EDIT : $ctrl.BP_VIEW.AUTOMATIONS)) : $ctrl.BP_VIEW.EDIT;
            setScrollContainer();
            setDefaultView($ctrl.activeTab);
            ProcessModalService.getActiveProcess();
            $ctrl.processId = $stateParams.slug;
            $ctrl.showPrint = $stateParams.print;
            $ctrl.title = $stateParams.title;
            getResources($ctrl.processId);
            setProcessSummaryLimit();
            $ctrl.froalaTextShortenConfig = ProcessService.froalaTextShortenConfig(RunDetailService.getDescriptionLimit());
            if ($state.params.action === 'blueprintDuplicated') {
              growl.success($filter('translate')('process.messages.duplicatedScreen'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            } else if ($state.params.importPublicBlueprint) {
              growl.success($filter('translate')('process.messages.importPublicBlueprint'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              $state.go($state.current, angular.extend($stateParams, { importPublicBlueprint: void 0 }), { notify: false, location: 'replace' });
            }
            FieldService.loadCaptureFields();
            $ctrl.isForceOnboardingFlow = ProcessService.isForceOnboarding();
            if ($ctrl.isForceOnboardingFlow) {
              history.pushState(null, document.title, location.href);
            }
            $ctrl.metadata.highlightedComment = $stateParams.comment;
            $ctrl.statusOptions = StepService.getProgressStatus();
            $ctrl.activeTab === 'readBP' ? $ctrl.isEditPreview = true : angular.noop();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unregisterProcessHandler();
            unregisterProcessHandlerViaDrawer();
            deadlineUpdateEventHandler();
            unRegisteredEventStepUpdated();
            stepChangesTimeoutHandler ? $timeout.cancel(stepChangesTimeoutHandler) : angular.noop();
            boxHeaderCheckingTimeoutHandler ? $timeout.cancel(boxHeaderCheckingTimeoutHandler) : angular.noop();
            onStepAddedHandler();
            processTypeWatcher();
            showButonEventHandler();
            rightPaneDocumentBuilderCloseHandler();
            prerunWatcher();
            rightPaneDocumentBuilderOpenHandler();
            angular.element('.app-body').removeClass('compact-step-snippet-active');
            appBody.removeClass('read-mode-blueprint');
            body.removeClass('read-mode');
            contentLanguageChangedHandler();
            unRegisteredPreferenceWatcherHandler();
            automationAddHandler();
            automationUpdateHandler();
            automationDeleteHandler();
            mainSectionHeightHandler ? $timeout.cancel(mainSectionHeightHandler) : angular.noop();
            windowElem.off('resize', onWindowResize);
            openAutomationPage();
            rightPaneOpenHandler();
            rightPaneCloseHandler();
            froalaFullscreenHandler();
            froalaHtmlHeightHandler();
            processStepsUpdatedHandler();
            selectedItemWatcherHandler();
            folderSelectedWatcher();
            folderRemovedWatcher();
            fieldChangesWatcher();
            stepPositionUpdated();
            body.removeClass('compact-task-active');
          }

          /**
           * @ngdoc method
           * @name getResources
           * @param {*} slug
           * @description Get resources
           */
          function getResources(slug) {
            $ctrl.isLoading = true;
            blockUI.start();
            getTagsFromAPI();
            var withStr = 'steps,steps.captures,steps.threads,tags,folder,steps.tags,steps.roles,assets,stages',
              resources = [
                ProcessService.get({
                  id: slug,
                  with: withStr + ($ctrl.isGuest ? ',guest_watchers.watcher' : ',member_watchers.watcher'),
                  skipNotFound: true
                }),
                store.getUsers(),
                StepService.getAllDeadlines({
                  id: $ctrl.processId,
                  in_working_days: false,
                  skipNotFound: true
                }),
                ProcessService.getPermission({
                  id: $ctrl.processId,
                  skipNotFound: true
                }),
                store.getLightweightGroups(),
                store.getLightweightGuests()
              ];

            if ($rootScope.userState === USER_STATE.MEMBER) {
              resources.push(StepService.getAllRoles());
            }

            $q.all(resources).then(function (response) {
              var process = response[0].data;
              if ($ctrl.isGuest) {
                _.set(process, 'current_guest_watchers', getCurrentUserWatcher(process.guest_watchers.data));
              } else {
                _.set(process, 'current_member_watchers', getCurrentUserWatcher(process.member_watchers.data));
              }
              $ctrl.orgGroups = response[4]; // get all org groups
              $ctrl.selectedStatus = _.find($ctrl.statusOptions, { value: process.status });
              process.steps.data = _.orderBy(process.steps.data, 'position');
              $ctrl.process = process;
              if ($rootScope.userState === USER_STATE.MEMBER) {
                $ctrl.roles = response[6].data;
              }
              if ($ctrl.process.folder_id) {
                getAllParentData($ctrl.process.folder_id);
              }
              $ctrl.process.can_edit_perms = response[3].data;
              getLastUpdatedDate();
              $rootScope.$emit("NEW_TAG:ADDED", $ctrl.process);
              $ctrl.process.guidance = ($ctrl.process.guidance) ? $ctrl.process.guidance : null;
              getAndAppliedPermissions();
              processName = $ctrl.process.title;
              translationHandler.updateTitle('global.pageTitle.singleTemplate', {
                title: $ctrl.process.title
              });
              //updateStepCount();
              $ctrl.orgAllUsers = UsersService.getAllOrgUsers(response[1]); // for all org users
              $ctrl.availableUsers = UsersService.getBilledUsers(response[1]); // for active billed users
              $ctrl.standardUsers = UsersService.getStandardUsers(response[1]); // for active standard users
              _.forEach(response[5], function (guest, key) {
                angular.extend(guest, { 'id': guest.email, 'text': guest.email, 'type': 'guest' });
              });
              $ctrl.availableGuests = response[5];

              //update deadlines
              updateDeadlines(response[2].data);
              setTagDOMContent();
              !$ctrl.selectedItem ? checkStepSelected() : angular.noop();
              oldProcess = angular.copy($ctrl.process);

              trackEvent({ label: 'BLUEPRINT VIEWED - ' + ($ctrl.BP_VIEW.READ === $ctrl.activeTab ? 'read' : 'edit') });

              if ($state.params.action === 'blueprintImported') {
                $ctrl.process.title = $filter('translate')('process.importedPrefix', { title: $ctrl.process.title });
                saveProcess('major_update', false);
                //remove action params from url without reload
                $state.go('.', { action: '' }, { notify: false });
              }
              blockUI.stop();
              $ctrl.isLoading = false;
              $ctrl.tocFocusElementId = $state.params.section;
              $timeout(function () {
                $ctrl.showTOC = !!$ctrl.tocFocusElementId;
              }, 350);
              calculatePercentage();
              if (mainSectionHeightHandler) {
                $timeout.cancel(mainSectionHeightHandler);
              }
              mainSectionHeightHandler = $timeout(onWindowResize, 500);
            }, function (error) {
              blockUI.stop();
              $ctrl.isLoading = false;
              if (error.status === 404) {
                growl.error($filter('translate')('steps.messages.bluePrintNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                $state.go('process.templates', {
                  org_id: _.get($rootScope.identity, 'default_organization.id')
                });
              }
            });
          }

          function onFavoriteClick(process) {
            Growl.clearAllMessages('global');
            blockUI.start();
            var resource = [];
            if (getCurrentUserWatcher(_.get($ctrl.process, 'member_watchers.data')).length > 0) {
              process.starred = false;
              var watcherData = angular.copy(getCurrentUserWatcher(_.get($ctrl.process, 'member_watchers.data'))),
                anotherUserWatcher = getAnotherUserWatcher(_.get($ctrl.process, 'member_watchers.data'));
              _.set($ctrl.process, 'member_watchers.data', anotherUserWatcher);
              _.set($ctrl.process, 'current_member_watchers', []);
              blockUI.stop();
              $timeout(function () {
                growl.success($filter('translate')('runs.messages.unFavorited'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 250);
              if (watcherData.id) {
                ProcessService.removeFavorite(_.toString(_.get(process, 'id')));
                FavoriteService.removeFavorite(watcherData.id);
              } else {
                ProcessService.removeFavorite(_.toString(_.get(process, 'id')));
              }
            } else {
              process.starred = true;
              resource = [
                ProcessService.setFavorite(_.toString(_.get(process, 'id'))),
                FavoriteService.addFavorite({
                  userId: _.get($rootScope, 'identity.id'),
                  objId: _.get(process, 'id'),
                  objectType: 'checklist'
                })
              ];
              $q.all(resource).then(function (res) {
                blockUI.stop();
                if (_.get(res[1], 'data')) {
                  $ctrl.process.member_watchers.data.push(res[1].data);
                  $ctrl.process.current_member_watchers.push(res[1].data);
                }
                $timeout(function () {
                  growl.success($filter('translate')('runs.messages.favorited'), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }, 250);
              });
            }
          }

          function getCurrentUserWatcher(data) {
            return FavoriteService.getCurrentUserWatcher(data);
          }

          function getAnotherUserWatcher(data) {
            return FavoriteService.getAnotherUserWatcher(data);
          }

          function setBlueprintStatus() {
            StepService.updateBlueprintStatus({
              checklist_id: _.get($ctrl.process, 'id'),
              status: _.get($ctrl.selectedStatus, 'value')
            }).then(function (response) {
              $ctrl.process.last_updated_unformatted = response.data.last_updated;
              getLastUpdatedDate();
            });
          }

          /**
           * @ngdoc method
           * @name getAndAppliedPermissions
           * @description get users permissions
           * and applied it.
           */
          function getAndAppliedPermissions() {
            if ($ctrl.process.can_edit_perms && !$ctrl.isAdminMember) {
              var currentUserID = $rootScope.identity.id, isLightMember = _.isEqual(_.get($rootScope, 'identity.role'), "light");
              $ctrl.havePermissionToEdit = !isLightMember && UtilsService.hasSpecificPermissions($ctrl.isAdminMember, $ctrl.process.created_by, currentUserID, _.get($ctrl.process, 'can_edit_perms.checklist_edit', []), _.get($ctrl.process, 'can_edit_perms.checklist_edit_group', []), $ctrl.orgGroups);
              $ctrl.havePermissionToRead = UtilsService.hasSpecificPermissions($ctrl.isAdminMember, $ctrl.process.created_by, currentUserID, _.get($ctrl.process, 'can_edit_perms.checklist_read', []), _.get($ctrl.process, 'can_edit_perms.checklist_read_group', []), $ctrl.orgGroups);
              $ctrl.havePermissionToLaunch = UtilsService.hasSpecificPermissions($ctrl.isAdminMember, $ctrl.process.created_by, currentUserID, _.get($ctrl.process, 'can_edit_perms.process_launch', []), _.get($ctrl.process, 'can_edit_perms.process_launch_group', []), $ctrl.orgGroups);
              $ctrl.havePermissionToDuplicate = UtilsService.hasSpecificPermissions($ctrl.isAdminMember, $ctrl.process.created_by, currentUserID, _.get($ctrl.process, 'can_edit_perms.checklist_duplicate', []), _.get($ctrl.process, 'can_edit_perms.checklist_duplicate_group', []), $ctrl.orgGroups);
              if ($ctrl.havePermissionToEdit && !$ctrl.havePermissionToRead) {
                $state.go('process.edit',
                  angular.extend($stateParams, { view: 'edit' }));
              } else if ($ctrl.havePermissionToRead && !$ctrl.havePermissionToEdit) {
                $state.go('process.edit',
                  angular.extend($stateParams, { view: 'read', print: void 0, step: void 0 }));
              } else if (!$ctrl.havePermissionToRead && !$ctrl.havePermissionToEdit) {
                $state.go('process.templates', {
                  org_id: _.get($rootScope.identity, 'default_organization.id')
                });
              }
            }
          }

          /**
           * @ngdoc method
           * @name saveProcess
           * @description Update process
           * @param {String} status
           * @param {*} showGrowl
           */
          function saveProcess(status, showGrowl, isTitleChanged) {
            if (!Helper.checkAccessAuthority())
              return;
            _.each($ctrl.process.steps.data, function (step) {
              StepService.checkUnsavedChanges(step).then(function (success) {
                if (success === 'SAVE_CHANGES') {
                  $rootScope.$broadcast('SAVE_STEP_CHANGES', step.id);
                }
              }, function (reject) {
                if (reject === 'DISCARD_CHANGES') {
                  $rootScope.$broadcast('DISCARD_STEP_CHANGES', step.id);
                }
              });
            });
            var majorUpdate = (status === 'major_update'),
              requestPayload = angular.copy($ctrl.process);

            // Update step meta
            if ($ctrl.process.allow_guests_to_track === 'no') {
              _.each($ctrl.process.steps.data, function (step, stepKey) {
                $ctrl.process.steps.data[stepKey].allow_guest_owners = false;
              });
            }

            requestPayload.kickoff_title = requestPayload.kickoff_title ? requestPayload.title : angular.noop();
            requestPayload.steps.data = removeDeadlineRules(requestPayload.steps.data);
            requestPayload.title = ProcessService.capitalizeFirstLetterOfEachWord(requestPayload.title);
            requestPayload.major_update = majorUpdate;
            $ctrl.onSaving = true;
            ProcessService.update({
              id: requestPayload.id
            }, requestPayload).then(function (response) {
              processName = response.data.title;
              majorUpdate && $ctrl.process.kickoff_title ? $ctrl.process.kickoff_title = response.data.title : angular.noop();
              $rootScope.$emit(TFY_EVENTS.STEP.UPDATE_DRAWER_STEP, { last_updated: response.data.last_updated });
              if (showGrowl) {
                var message = isTitleChanged ? $filter('translate')('process.messages.blueprintNameUpdated') : $filter('translate')('process.messages.saveMajorUpdate');
                growl.success(message, {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }
              var preruns = _.get(response, 'data.prerun');
              isTitleChanged = false;
              $ctrl.process.prerun = preruns;
              $ctrl.onSaving = false;
              $rootScope.$emit('ACTIVITY:UPDATES', { auditableId: $ctrl.process.id, auditableType: 'checklist' });
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name removeDeadlineRules
           * @description Remove deadline rules from steps
           * @param {Array} steps
           * @returns {Array}
           */
          function removeDeadlineRules(steps) {
            var tempSteps = angular.copy(steps);
            _.each(tempSteps, function (step) {
              if (step.hasOwnProperty('deadline_rules')) {
                delete step.deadline_rules;
              }
            });
            return tempSteps;
          }

          /**
           * @ngdoc method
           * @name checkProcessTitleChanged
           * @description Check process title has been changed or not
           * @param {String} title
           * @returns {Boolean}
           */
          function checkProcessTitleChanged(title) {
            if (title === $ctrl.process.title) {
              return false;
            }
          }

          /**
           * @ngdoc method
           * @name checkKOFormsField
           * @description Check Kick off form field available
           * @returns {Boolean}
           */
          function checkKOFormField() {
            if (_.get($ctrl.process, 'prerun', []).length > 0) {
              return !_.filter(_.get($ctrl.process, 'prerun', []), function (item) {
                return !_.has(item, 'isNew');
              }).length;
            } else {
              return !_.get($ctrl.process, 'prerun', []).length;
            }
          }

          /**
           * @ngdoc method
           * @name startRun
           * @description open process modal for create or start run
           */
          function startRun() {
            var preruns = [],
              args = { process_id: $ctrl.process.id };
            $timeout(function () {
              Growl.clearAllMessages('growlNotification');
            }, 500);
            if (!Helper.isObjectEmpty($ctrl.prerunValues)) {
              _.forOwn($ctrl.prerunValues, function (val, key) {
                var prerun = _.find($ctrl.process.prerun, { id: key });
                if (prerun) {
                  var value = {};
                  value[prerun.alias] = val.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                  preruns.push(value);
                }
              });
              if (!Helper.isObjectEmpty(preruns)) {
                args.ko_fields = JSON.stringify(preruns);
              }
            }
            if (!Helper.checkAccessAuthority() || $ctrl.isLaunchBtnDisabled())
              return;
            if (AuthPlan.isRestrictedWithDocsPlan()) {
              return AuthPlan.setModalContent(AuthPlan.getCurrentPlanCode(), 'launchBP');
            }
            if ($ctrl.selectedStep && StepService.showInvalidStepFields($ctrl.selectedStep, $ctrl.selectedStepLastActiveTab)) {
              return;
            }
            if (((_.get($ctrl.process, 'steps.data', []).length <= 0 && !$ctrl.isProcessHavingFormType) || ($ctrl.checkKOFormField() && $ctrl.isProcessHavingFormType)) && !($ctrl.isProcessHavingDocumentType && !$ctrl.checkKOFormField())) {
              return;
            }
            if (ProcessService.isForceOnboarding('HasLaunchedBP')) {
              ProcessService.setForceOnboardingFlowData({ key: 'HasLaunchedBP', value: 'yes' });
            }
            $state.go('run.create', args);
          }

          /**
           * @ngdoc method
           * @name updateStepCount
           * @description a method to update the step count
           */
          /*  function updateStepCount() {
             $ctrl.totalSteps = $ctrl.process.steps.data.length;
             countStepsAndPrerun();
           } */

          /**
           * @ngdoc method
           * @name countStepsAndPrerun
           * @description count total number of steps and prerun
           */
          /* function countStepsAndPrerun() {
            $ctrl.totalStepsAndPrerun = $ctrl.totalSteps + $ctrl.process.prerun.length;
            $rootScope.$emit("STEP:COUNT_TOTAL_STEPS_AND_PRERUN", $ctrl.totalStepsAndPrerun);
          } */

          showButonEventHandler = $rootScope.$on('ON_LOADING_PAGE:HIDE_BUTTON', function () {
            $ctrl.hideButton = true;
          });

          deadlineUpdateEventHandler = $rootScope.$on('STEP:UPDATE_DEADLINE', function () {
            updateStepDeadlines();
          });

          function updateStepDeadlines() {
            StepService.getAllDeadlines({
              id: $ctrl.processId,
              in_working_days: false,
              skipNotFound: true
            }).then(function (response) {
              updateDeadlines(response.data);
              $rootScope.$emit("PROCESS:STEP_HIGHEST_DEADLINE", $ctrl.highestDeadline);
            }, function (error) {
              $log.error(error);
            });
          }

          /**
           * @ngdoc method
           * @name toggleBPEDetail
           * @description Toggle BPE detail sidebar
           */
          function toggleBPEDetail() {
            if (!RunDetailService.isDrawerOpen()) {
              openDrawer();
            } else {
              $rootScope.$emit('BPE_DETAIL:CLOSE');
            }
          }

          /**
           * @ngdoc method
           * @name openDrawer
           * @description open up the more detail drawer
           */
          function openDrawer() {
            if (!RunDetailService.isDrawerOpen()) {
              moreDetailDrawerInstance = $aside.open({
                component: 'templateEdit',
                placement: 'right',
                windowClass: 'more-detail-drawer-modal bp-detail',
                size: 'sm',
                backdrop: true,
                resolve: {
                  process: function () {
                    return $ctrl.process;
                  },
                  users: function () {
                    return $ctrl.availableUsers;
                  },
                  guests: function () {
                    return $ctrl.availableGuests;
                  },
                  isProcessTemplate: function () {
                    return true;
                  },
                  standardUsers: function () {
                    return $ctrl.standardUsers;
                  },
                  orgAllUsers: function () {
                    return $ctrl.orgAllUsers;
                  },
                  orgGroups: function () {
                    return $ctrl.orgGroups;
                  },
                  focusMe: function () {
                    return $ctrl.focusMe;
                  },
                  highestDeadline: function () {
                    return $ctrl.highestDeadline;
                  },
                  havePermissionToEdit: function () {
                    return $ctrl.havePermissionToEdit;
                  },
                  havePermissionToRead: function () {
                    return $ctrl.havePermissionToRead;
                  },
                  havePermissionToLaunch: function () {
                    return $ctrl.havePermissionToLaunch;
                  },
                  havePermissionToDuplicate: function () {
                    return $ctrl.havePermissionToDuplicate;
                  },
                  toggleProcessTab: function () {
                    return $ctrl.toggleProcessTab;
                  },
                  openPowerBuilder: function () {
                    return $ctrl.openPowerBuilder;
                  },
                  activeSettingsTab: function () {
                    return $ctrl.selectedSettingsTab;
                  }
                }
              });

              moreDetailDrawerInstance.rendered.then(function () {
                $uibModalStack.getTop().value.modalDomEl.attr('id', 'more-detail-drawer');
              });

              moreDetailDrawerInstance.result.then(function (result) {
                $ctrl.process = _.merge($ctrl.process, result);
                $ctrl.process.prerun = result.prerun;
                /* countStepsAndPrerun(); */
              });

              moreDetailDrawerInstance.opened.then(function () {
                RunDetailService.openDrawer();
              });

              moreDetailDrawerInstance.closed.then(function () {
                $ctrl.focusMe = false;
                $ctrl.selectedSettingsTab = null;
                RunDetailService.closeDrawer();
                $rootScope.$emit('DRAWER:CLOSED');
              });
            }
          }

          /**
           * Listen on process update
           * @type {*|(function())}
           */
          unregisterProcessHandler = $rootScope.$on("PROCESS:UPDATED", function ($event, data) {
            angular.extend($ctrl.process, data.process);
            if (data.koDescUpdate) {
              oldProcess.kickoff_title = $ctrl.process.kickoff_title;
              oldProcess.kickoff_description = $ctrl.process.kickoff_description;
            } else {
              oldProcess = angular.copy($ctrl.process);
            }
            if (data.updateDeadline) {
              updateStepDeadlines();
            }
          });

          /**
           * Listen on process update at drawer
           * @type {*|(function())}
           */
          unregisterProcessHandlerViaDrawer = $rootScope.$on("DRAWER:PROCESS:UPDATED", function () {
            setTagDOMContent();
          });

          /**
           * @ngdoc method
           * @name setProcessSummaryLimit
           * @description set the process summary limit based on screen size
           */
          function setProcessSummaryLimit() {
            $ctrl.summaryLimit = RunDetailService.getProcessSummaryLimit();
          }

          /**
           * @function
           * @name openEditRunNameModal
           * @private
           * @description open edit run name modal
           */
          //TODO: Remove edit-run-name-modal.html once this change is approved and merged
          function openEditRunNameModal() {
            if (!Helper.checkAccessAuthority())
              return;
            modalInstance = $uibModal.open({
              component: 'processRenameModal',
              windowClass: 'process-edit-modal',
              backdrop: 'static',
              resolve: {
                titleName: function () {
                  return $ctrl.process.title;
                }
              }
            });
            modalInstance.result.then(function (res) {
              $ctrl.process.title = res;
              saveProcess('major_update', true);
            }, function () {
              $log.info('modal is cancelled');
            });
          }

          /**
           * @ngdoc method
           * @name archiveProcess
           * @description archive a process
           */
          function archiveProcess() {
            if (!Helper.checkAccessAuthority())
              return;
            blockUI.start();
            ProcessService.archiveProcess({
              id: $ctrl.process.id
            }).then(function () {
              blockUI.stop();
              growl.success($filter('translate')('process.messages.archive'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true,
                variables: {
                  method: unArchiveProcess,
                  param: $ctrl.process,
                  linkText: $filter('translate')('runs.dashboard.undo')
                }
              });
              $state.go('process.templates', {
                org_id: _.get($rootScope.identity, 'default_organization.id')
              });
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name unArchiveProcess
           * @description unarchive a process
           * @param {*} process
           */
          function unArchiveProcess(process) {
            if (!Helper.checkAccessAuthority() || !process)
              return;
            blockUI.start();
            Growl.clearAllMessages('global');
            ProcessService.unArchiveProcess({
              id: process.id
            }).then(function (data) {
              $state.go('process.edit', {
                slug: data.data.id
              });
              growl.success($filter('translate')('process.messages.unArchive'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              blockUI.stop();
            }, function (error) {
              growl.error(error.message);
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name setStepsAbsoluteDeadlines
           * @description Set the absolute deadlines for steps
           * @param {*} deadlines
           */
          function setStepsAbsoluteDeadlines(deadlines) {
            var steps = $ctrl.process.steps.data;
            _.forEach(steps, function (step) {
              var deadline = _.find(deadlines, {
                step_id: step.id
              });
              if (!_.isUndefined(deadline)) {
                step.absolute_deadline = deadline.deadline_unformatted;
                step.deadlineAsHumanized = humanizeDeadline(step.absolute_deadline).replace('in', '');
                step.deadlineAsString = StepService.getStepDeadlineAsStr(steps, step);
              }
            });
          }

          /**
           * @ngdoc method
           * @name humanizeDeadline
           * @param deadline in absolute form
           * @returns {String} date difference in humanized form
           */
          function humanizeDeadline(deadline) {
            return $filter('tallyfyTimeAgo')(deadline);
          }

          /**
           * @ngdoc method
           * @name updateDeadlines
           * @description An helper function that update absolute deadlines and highestDeadline(duration of process)
           * @param {*} data An array of steps
           *
           * @return void
           */
          function updateDeadlines(data) {
            $ctrl.highestDeadline = ProcessService.getProcessDeadline(data, 'deadline_unformatted');
            setStepsAbsoluteDeadlines(data);
          }

          /**
           * @ngdoc ngdoc
           * @name getTagsFromAPI
           * @private
           *
           * @description get tags
           * @returns void
           */
          function getTagsFromAPI() {
            $ctrl.tagsPromise = $q.all([
              TagsService.get({
                action: 'tags',
                q: BLUEPRINT_TYPE.SNIPPET,
                strict: true
              }),
              TagsService.get({
                action: 'tags',
                q: BLUEPRINT_TYPE.DOCUMENT,
                strict: true
              })
            ]).then(function (res) {
              if (res[0].length && !res[1].length) {
                migrateOldSnippetTag(res[0]);
              }
            });
          }

          /**
           * @ngdoc method
           * @name toggleProcessTab
           * @param tabName
           * @param print
           * @description toggle process tabs(edit/read)
           */
          function toggleProcessTab(tabName, print) {
            $ctrl.showPrint = print;
            if ($ctrl.tocFocusElementId) {
              $ctrl.tocFocusElementId = void 0;
              $ctrl.showTOC = false;
              $state.go($state.current, angular.extend($stateParams, { section: void 0 }), { notify: false, location: 'replace' });
            }
            if ($ctrl.selectedStep && StepService.showInvalidStepFields($ctrl.selectedStep, $ctrl.selectedStepLastActiveTab)) {
              return;
            }
            if ($ctrl.activeTab !== tabName) {
              setDefaultView(tabName);
              if (tabName === 'readBP') {
                $ctrl.isEditPreview = true;
                changeProcessTab(tabName);
                $ctrl.selectedItem = void 0;
                $ctrl.stepActiveTab = void 0;
                $ctrl.metadata = {};
                toggleTocView(null, true);
                $state.go($state.current, angular.extend($stateParams, { print: print || void 0, step: void 0 }), { notify: false, location: 'replace' });
                DOMService.scrollViewToTop(DOM.MASTER_VIEW.ELEMENT);
                appBody.addClass('read-mode-blueprint');
                body.addClass('read-mode');
                $rootScope.$emit('BLUEPRINT:TOGGLE_VIEW', { view: tabName });
              } else {
                tabName === 'editBP' ? $ctrl.isEditPreview = false : angular.noop();
                $state.go($state.current, angular.extend($stateParams, { view: 'edit' }), { notify: false, location: 'replace' });
                changeProcessTab(tabName);
                appBody.removeClass('read-mode-blueprint');
                body.removeClass('read-mode');
              }
              setScrollContainer();
              if (mainSectionHeightHandler) {
                $timeout.cancel(mainSectionHeightHandler);
              }
              mainSectionHeightHandler = $timeout(onWindowResize, 500);
              $timeout(function () {
                blockUI.stop();
              }, 500);
            }
          }

          /**
           * @ngdoc method
           * @name getAllParentData
           * @param folderId
           * @description get breadcrumb
           */
          function getAllParentData(folderId) {
            var params = { id: folderId, with: 'parent', skipNotFound: true };
            FolderService.getFolders(params).then(function (res) {
              $ctrl.breadcrumb = res.data;
              if ($ctrl.breadcrumb && $ctrl.breadcrumb.parent && $ctrl.breadcrumb.parent.data) {
                var totalLevels = prepareBreadcrumbData($ctrl.breadcrumb, 1);
                angular.extend($ctrl.breadcrumb, { totalLevels: totalLevels });
              }
            }, function () {
              $ctrl.breadcrumbLoading = false;
            });
          }

          function prepareBreadcrumbData(breadcrumb, level) {
            angular.extend(breadcrumb, { level: level });
            if (breadcrumb.parent) {
              level++;
              return prepareBreadcrumbData(breadcrumb.parent.data, level);
            }
            return level;
          }

          /**
           * @ngdoc method
           * @name changeProcessTab
           * @param tabName
           * @description change the process tab
           */
          function changeProcessTab(tabName) {
            $ctrl.activeTab = tabName;
          }

          /**
           * @ngdoc method
           * @name getTags
           * @param {Boolean} isToolTip
           * @description get tags
           */
          function getTags(isToolTip) {
            if (!isToolTip) {
              return $ctrl.process.tags.data;
            }
            return ProcessService.getTagsWithHash(_.get($ctrl.process, 'tags.data', []), isToolTip, 30);
          }

          function setTagDOMContent() {
            $ctrl.isProcessHavingFormType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.FORM;
            $ctrl.isProcessHavingProcedureType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.PROCEDURE;
            $ctrl.isProcessHavingDocumentType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.DOCUMENT;
            $ctrl.tagsText = getTags(false);
            $ctrl.tagsTooltipText = getTags(true);
            $ctrl.tocTagsInOrder = $ctrl.isProcessHavingDocumentType ? ['h1', 'h2', 'h3', 'h4'] : ['.step-title'];
          }

          function setDefaultView(tabName) {
            var view = $ctrl.BP_VIEW.READ === tabName ? 'read' : ($ctrl.BP_VIEW.EDIT === tabName ? 'edit' : 'automations');
            var stateParams = {
              view: view || 'edit'
            };
            if (view === 'read') {
              angular.extend(stateParams, { step: void 0 });
              appBody.addClass('read-mode-blueprint');
              body.addClass('read-mode');
            } else if (view === 'edit') {
              appBody.removeClass('read-mode-blueprint');
              body.removeClass('read-mode');
            } else {
              angular.extend(stateParams, { step: void 0 });
              appBody.removeClass('read-mode-blueprint');
              body.removeClass('read-mode');
              appBody.removeClass('read-mode-blueprint');
              body.removeClass('read-mode');
            }
            $state.transitionTo($state.current, angular.extend($stateParams, stateParams), { notify: false });
          }

          function onTabChanged(tab, category) {
            var args = {
              activeTab: tab
            };

            if (category) {
              angular.extend(args, { activeCategory: category });
            }

            dirtyHandler(args).then(
              function (status) {
                if (status.isValid) {
                  if (status.category) {
                    setActiveCategory(status.category);
                  }
                  if ($ctrl.stepActiveTab.value === tab.value || Helper.isObjectEmpty(status.tab)) {
                    setTab({});
                  } else {
                    setTab(status.tab);
                  }
                } else if ($ctrl.stepActiveTab.value === "logic") {
                  $rootScope.$emit("STEP_RULES:INVALID");
                } else {
                  angular.noop();
                }
              }, function (status) {
                status.isValid ? setTab(status.tab) : angular.noop();
              }
            );
          }

          /**
           * @ngdoc method
           * @name dirtyCheckHandler
           * @param {*} args
           *
           * @description
           * callback before item switched
           */
          function dirtyCheckHandler(args) {
            if (_.get(args, 'isChatBoxOpen')) {
              $rootScope.$emit('BLUEPRINT:COMMENTS_OPEN', { isOpen: _.get(args, 'isChatBoxOpen') });
            }
            $ctrl.metadata.highlightedComment = void 0;
            $state.go($state.current, angular.extend($stateParams, { comment: void 0 }), { notify: false, location: 'replace' });
            var defer = $q.defer();
            if ($ctrl.selectedItem) {
              dirtyHandler(args).then(function (status) {
                status.isValid ? setTab(status.tab) : angular.noop();
                defer.resolve({ isValid: args.activeTab ? false : status.isValid });
              }, function (status) {
                status.isValid ? setTab(status.tab) : angular.noop();
                defer.resolve({ isValid: args.activeTab ? false : status.isValid });
              }
              );
            } else {
              setTab(args.activeTab || args.item.activeTab);
              defer.resolve({ isValid: !!!args.activeTab });
            }
            return defer.promise;
          }

          /**
           * @ngdoc method
           * @name checkStepSelected
           *
           * @description
           * check selected step
           */
          function checkStepSelected() {
            if ($ctrl.selectedItem || !$stateParams.step) {
              // Wait for process-sub-header to register first
              $timeout(function () {
                toggleProcessSubHeaderIndex(false);
              }, 300);
              return;
            }
            var stepId = $stateParams.step;
            if (stepId === 'process-description') {
              if ($ctrl.process.summary) {
                $ctrl.selectedItem = {
                  id: 'process-description',
                  process: $ctrl.process
                };
              } else {
                $state.go($state.current, angular.extend($stateParams, { step: void 0 }), { notify: false, location: 'replace' });
              }
            } else if (stepId === 'ko-step') {
              if ($ctrl.process.kickoff_title || !$ctrl.checkKOFormField()) {
                $ctrl.selectedItem = {
                  id: 'ko-step',
                  process: $ctrl.process
                };
              } else {
                $state.go($state.current, angular.extend($stateParams, { step: void 0 }), { notify: false, location: 'replace' });
              }
            } else if (stepId === 'others') {
              $ctrl.selectedItem = {
                id: 'others',
                process: $ctrl.process
              };
            } else {
              var index = _.findIndex($ctrl.process.steps.data, { alias: stepId });
              if (index > -1) {
                var stepTabs = StepService.getStepOptions($ctrl.process);
                $ctrl.selectedItem = {
                  index: index,
                  step: $ctrl.process.steps.data[index],
                  activeTab: stepTabs[0]
                };
              } else {
                var selectedStep = _.find($ctrl.process.steps.data, { id: stepId });
                if (!Helper.isObjectEmpty(selectedStep)) {
                  var stepTabs = StepService.getStepOptions($ctrl.process);
                  var index = _.findIndex($ctrl.process.steps.data, { id: stepId });
                  $ctrl.selectedItem = {
                    index: index,
                    step: selectedStep,
                    activeTab: stepTabs[0]
                  };
                  $state.go($state.current, angular.extend($stateParams, { step: selectedStep.alias }), { notify: false, location: 'replace' });
                }
              }
            }
            //Setup edit step categories
            setupStepCategories();
          }

          /**
           * @ngdoc method
           * @name getSelectedStepAlias
           * @param {*} selectedItem
           *
           * @description
           * get selected step alias / type
           */
          function getSelectedStepAlias(selectedItem) {
            var id;
            if (selectedItem.id === 'ko-step') {
              id = 'ko-step';
            } else if (selectedItem.id === 'others') {
              id = 'others';
            } else if (selectedItem.id === 'process-description') {
              id = 'process-description';
            } else {
              id = selectedItem.step.alias;
            }
            return id;
          }

          /**
           * @ngdoc method
           * @name onItemSwitched
           * @param {*} item
           *
           * @description
           * on item switched
           */
          function onItemSwitched(item) {
            var stepId = getSelectedStepAlias(item);
            $ctrl.stepTitleEditMode = item.editStepTitle;
            $state.go($state.current, angular.extend($stateParams, { step: stepId }), { notify: false, location: 'replace' });
          }

          /**
           * @ngdoc method
           * @name beforeRightPaneClose
           * @param {*} args
           *
           * @description
           * callback before right pane close
           */
          function beforeRightPaneClose(args) {
            var defer = $q.defer();
            dirtyHandler(args).then(
              function (status) {
                status.isValid ? setTab(status.tab) : toggleProcessSubHeaderIndex(true);
                defer.resolve({ isValid: status.isValid });
              }, function (status) {
                status.isValid ? setTab(status.tab) : toggleProcessSubHeaderIndex(true);
                defer.resolve({ isValid: status.isValid });
              }
            );
            return defer.promise;
          }

          /**
           * @ngdoc method
           * @name onRightPaneClosed
           *
           * @description
           * on right pane closed method callback
           */
          function onRightPaneClosed() {
            $state.go($state.current, angular.extend($stateParams, { step: void 0 }), { notify: false, location: 'replace' });
          }

          /**
           * @ngdoc method
           * @name dirtyHandler
           * @param {*} args
           *
           * promise method to check dirty on step
           */
          function dirtyHandler(args) {
            var defer = $q.defer();
            var isProcessDirty = isStepDirty(args);
            if (isProcessDirty && !isSelectedStepValid(args)) {
              var status = saveSelectedItem(args);
              if (args) {
                defer.resolve({
                  isValid: status,
                  tab: args.activeTab || args.item.activeTab,
                  category: args.activeCategory || $ctrl.activeCategory
                });
              } else {
                defer.resolve({
                  isValid: status
                });
              }
            } else {
              if ($ctrl.stepDescribeForm.$dirty && _.get($ctrl.stepActiveTab, 'value') === "form_fields") {
                discardSelectedItem();
              }
              var tab, category;
              if (args) {
                tab = args.activeTab || args.item.activeTab;
                category = args.activeCategory || $ctrl.activeCategory;
              }
              defer.resolve({
                isValid: true,
                tab: tab,
                category: category
              });
            }
            return defer.promise;
          }

          /**
           * @ngdoc method
           * @name dirtyHandlerOpenConfimModal
           * @param {*} args
           * promise method to check dirty on step
           */
          //TODO: DO NOT DELETE THIS FUNCTION : It can be unhide in future
          /*function dirtyHandlerOpenConfirmModal(args) {
            var defer = $q.defer();
            var isProcessDirty = isStepDirty();
            if (isProcessDirty) {
              StepService.openConfirmDirtyCheckModal().then(
                function () {
                  var status = saveSelectedItem();
                  if (args) {
                    defer.resolve({
                      isValid: status,
                      tab: args.activeTab || args.item.activeTab
                    });
                  } else {
                    defer.resolve({
                      isValid: status
                    });
                  }
                }, function () {
                  discardSelectedItem();
                  if (args) {
                    defer.resolve({
                      isValid: true,
                      tab: args.activeTab || args.item.activeTab
                    });
                  } else {
                    defer.resolve({
                      isValid: true
                    });
                  }
                  $rootScope.$emit('PROCESS:DISCARD', { process: $ctrl.process });
                }
              );
            } else {
              var tab;
              if (args) {
                tab = args.activeTab || args.item.activeTab;
              }
              defer.resolve({
                isValid: true,
                tab: tab
              });
            }
            return defer.promise;
          }*/

          /**
           * @ngdoc method
           * @name setTab
           *
           * @description change active step tab value
           * @param {*} tab
           */
          function setTab(tab) {
            stepChangesTimeoutHandler = $timeout(function () {
              $ctrl.stepActiveTab = tab;
            }, 0);
          }

          /**
           * @ngdoc method
           * @name isStepDirty
           *
           * @description
           * check if process is dirty
           */
          function isStepDirty(args) {
            var isDirty = false;
            if (_.get($ctrl.selectedItem, 'id') === 'process-description') {
              isDirty = $ctrl.process.summary !== oldProcess.summary;
            } else if (_.get($ctrl.selectedItem, 'id') === 'ko-step') {
              isDirty = isKOFormStepDirty();
            } else if (_.get($ctrl.selectedItem, 'id') === 'others') {
              isDirty = false;
            } else {
              isDirty = isSelectedStepDirty(args);
            }
            return isDirty;
          }

          /**
           * @ngdoc method
           * @name isKOFormDirty
           *
           * @description
           * check if ko step is dirty
           */
          function isKOFormStepDirty() {
            var isKOFormDirty = koStepService.isKOFormDirty($ctrl.process, oldProcess),
              isKOFormInvalid = StepService.validatePrerunFields($ctrl.process),
              isKODescDirty = $ctrl.process.kickoff_description !== oldProcess.kickoff_description;
            return isKOFormDirty || isKODescDirty || isKOFormInvalid;
          }

          /**
           * @ngdoc method
           * @name isSelectedStepDirty
           *
           * @description
           * check if selected step is dirty
           */
          function isSelectedStepDirty(args) {
            var activeTab = _.get($ctrl, 'stepActiveTab', {}), stepSelected = _.find($ctrl.process.steps.data, { id: _.get($ctrl, 'selectedItem.step.id') }), isDirty = false;
            if (!stepSelected) {
              return false;
            }
            switch ($ctrl.activeCategory.key) {
              case 'basics':
                if (args && args.category) {
                  isDirty = $ctrl.stepDescribeForm.$dirty || $ctrl.assignForm.$dirty || $ctrl.deadlineForm.$dirty;
                } else {
                  if (activeTab.value === 'describe_capture') {
                    isDirty = $ctrl.stepDescribeForm.$dirty;
                  } else if (activeTab.value === 'assign') {
                    isDirty = $ctrl.assignForm.$dirty;
                  } else if (activeTab.value === 'set-deadline') {
                    isDirty = $ctrl.deadlineForm.$dirty;
                  }
                }
                break;
              case 'fields':
                isDirty = StepService.isStepCapturesInvalid(stepSelected);
                break;
              case 'automations':
                isDirty = $ctrl.logicForm.$dirty;
                break;
              case 'advanced':
                if (_.get($ctrl.settingsForm, 'launch_another_process_when_complete.$modelValue')) {
                  if (!_.get(stepSelected, 'bp_to_launch.id')) {
                    setForms('$setSubmitted');
                    isDirty = true;
                    DOMService.centerObjectToView('.from-blueprint.has-error', {
                      behavior: "smooth",
                      block: "center"
                    });
                  } else {
                    isDirty = false;
                  }
                } else {
                  isDirty = $ctrl.settingsForm.$dirty;
                }
                break;
              default:
                break;
            }
            // if (activeTab.value === 'describe_capture') {
            //   isDirty = $ctrl.stepDescribeForm.$dirty;
            // } else if (activeTab.value === 'form_fields') {
            //   isDirty = StepService.isStepCapturesInvalid(stepSelected);
            // } else if (activeTab.value === 'assign') {
            //   isDirty = $ctrl.assignForm.$dirty;
            // } else if (activeTab.value === 'set-deadline') {
            //   isDirty = $ctrl.deadlineForm.$dirty;
            // } else if (activeTab.value === 'logic') {
            //   isDirty = $ctrl.logicForm.$dirty;
            // } else if (activeTab.value === 'settings') {
            //   if (_.get($ctrl.settingsForm, 'launch_another_process_when_complete.$modelValue')) {
            //     if (!_.get(stepSelected, 'bp_to_launch.id')) {
            //       setForms('$setSubmitted');
            //       isDirty = true;
            //       DOMService.centerObjectToView('.from-blueprint.has-error', {
            //         behavior: "smooth",
            //         block: "center"
            //       });
            //     } else {
            //       isDirty = false;
            //     }
            //   } else {
            //     isDirty = $ctrl.settingsForm.$dirty;
            //   }
            // }
            return isDirty;
          }

          /**
           * @ngdoc method
           * @name saveSelectedItem
           *
           * @description
           * save selected item if any update
           */
          function saveSelectedItem(args) {
            var isFormValid = isSelectedStepValid(args);
            if (isFormValid) {
              if ($ctrl.selectedItem.id === 'process-description') {
                ProcessService.update({
                  id: $ctrl.process.id
                }, $ctrl.process).then(function (response) {
                  $rootScope.$emit('PROCESS:UPDATED', { process: response });
                });
              } else if ($ctrl.selectedItem.id === 'ko-step') {
                koStepService.saveKOStep($ctrl.process, true).then(function (response) {
                  $rootScope.$emit('PROCESS:UPDATED', { process: response });
                });
              } else {
                if (!$ctrl.selectedItem.step.webhookEnable) {
                  $ctrl.selectedItem.step.webhook = '';
                }
                StepService.updateStep({
                  id: $ctrl.selectedItem.step.id,
                  checklist_id: $ctrl.process.id,
                  skipNotFound: true
                }, $ctrl.selectedItem.step).then(function (response) {
                  var activeTab = _.get($ctrl, 'stepActiveTab', {});
                  $rootScope.$emit('STEP:UPDATED', {
                    step: response.data,
                    updateDeadline: activeTab.value === 'set-deadline'
                  });
                });
              }
              setForms('$setPristine');
              oldProcess = angular.copy($ctrl.process);
            } else {
              if ($ctrl.selectedItem.id === 'process-description') {
                $ctrl.processDescriptionForm.$setSubmitted();
              } else if ($ctrl.selectedItem.id === 'ko-step') {
                $ctrl.koStepForm.$setSubmitted();
                DOMService.centerObjectToView('.ko-form-fields-values .form-control.ng-invalid', {
                  behavior: "smooth",
                  block: "center"
                });
              } else {
                setForms('$setSubmitted');
                DOMService.centerObjectToView('.card-edit-form .form-control.ng-invalid', {
                  behavior: "smooth",
                  block: "center"
                });
              }
            }
            return isFormValid;
          }

          /**
           * @ngdoc method
           * @name isSelectedStepValid
           *
           * @description
           * check if selected step id dirty
           */
          function isSelectedStepValid(args) {
            var isValid = false;
            if ($ctrl.selectedItem.id === 'process-description') {
              isValid = true;
            } else if ($ctrl.selectedItem.id === 'ko-step') {
              isValid = !StepService.validatePrerunFields($ctrl.process);
            } else {
              var activeTab = _.get($ctrl, 'stepActiveTab', {});
              switch ($ctrl.activeCategory.key) {
                case 'basics':
                  if (args && args.activeCategory) {
                    isValid = $ctrl.stepDescribeForm.$valid && $ctrl.assignForm.$valid && $ctrl.deadlineForm.$valid;
                  } else {
                    if (activeTab.value === 'describe_capture') {
                      isValid = $ctrl.stepDescribeForm.$valid;
                    } else if (activeTab.value === 'assign') {
                      isValid = $ctrl.assignForm.$valid;
                    } else if (activeTab.value === 'set-deadline') {
                      isValid = $ctrl.deadlineForm.$valid;
                    }
                  }
                  break;
                case 'fields':
                  var stepSelected = _.find($ctrl.process.steps.data, { id: $ctrl.selectedItem.step.id });
                  isValid = !StepService.validateStepFields(stepSelected);
                  break;
                case 'automations':
                  isValid = $ctrl.logicForm.$valid;
                  break;
                case 'advanced':
                  isValid = $ctrl.settingsForm.$valid;
                  break;
                default:
                  break;
              }
              // if (activeTab.value === 'describe_capture') {
              //   var stepSelected = _.find($ctrl.process.steps.data, { id: $ctrl.selectedItem.step.id });
              //   isValid = $ctrl.stepDescribeForm.$valid && !StepService.validateStepFields(stepSelected);
              // } else if (activeTab.value === 'assign') {
              //   isValid = $ctrl.assignForm.$valid;
              // } else if (activeTab.value === 'set-deadline') {
              //   isValid = $ctrl.deadlineForm.$valid;
              // } else if (activeTab.value === 'logic') {
              //   isValid = $ctrl.logicForm.$valid;
              // } else if (activeTab.value === 'settings') {
              //   isValid = $ctrl.settingsForm.$valid;
              // }
            }
            return isValid;
          }

          /**
           * @ngdoc method discardSelectedItem
           * @name discardSelectedItem
           *
           * @description
           * discard selected item
           */
          function discardSelectedItem() {
            if ($ctrl.selectedItem.id === 'process-description') {
              $ctrl.process.summary = oldProcess.summary;
              // emit process updated event
              $rootScope.$emit('PROCESS:UPDATED', { process: $ctrl.process });
            } else if ($ctrl.selectedItem.id === 'ko-step') {
              $ctrl.process.kickoff_description = oldProcess.kickoff_description;
              koStepService.discardKOFormStep($ctrl.process, oldProcess);
              // emit process updated event
              $rootScope.$emit('PROCESS:UPDATED', { process: $ctrl.process });
            } else {
              var activeTab = _.get($ctrl, 'stepActiveTab', {});
              var stepSelected = _.find($ctrl.process.steps.data, { id: $ctrl.selectedItem.step.id });
              var oldStepSelected = _.find(oldProcess.steps.data, { id: $ctrl.selectedItem.step.id });
              if (activeTab.value === 'describe_capture') {
                stepSelected.summary = oldStepSelected.summary;
                StepService.discardStepFormFields(stepSelected, oldStepSelected);
              } else {
                _.extend(stepSelected, oldStepSelected);
                if (activeTab.value === 'settings') {
                  if (!stepSelected.webhook) {
                    stepSelected.webhookEnable = false;
                  }
                }
              }
              setForms('$setPristine');
              // emit event step updated to $rootScope
              $rootScope.$emit('STEP:UPDATED', {
                step: stepSelected,
                updateDeadline: activeTab.value === 'set-deadline'
              });
            }
          }

          /**
           * @ngdoc method
           * @name setForms
           * @param {*} actMethod
           *
           * @description
           * set form action method callback
           */
          function setForms(actMethod) {
            var activeTab = _.get($ctrl, 'stepActiveTab', {});
            if (actMethod === '$setSubmitted') {
              if (activeTab.value === 'describe_capture') {
                $ctrl.stepDescribeForm.$setSubmitted();
                $ctrl.captureForm.$setSubmitted();
              } else if (activeTab.value === 'assign') {
                $ctrl.assignForm.$setSubmitted();
              } else if (activeTab.value === 'set-deadline') {
                $ctrl.deadlineForm.$setSubmitted();
              } else if (activeTab.value === 'logic') {
                $ctrl.logicForm.$setSubmitted();
              } else if (activeTab.value === 'settings') {
                $ctrl.settingsForm.$setSubmitted();
              }
            } else if (actMethod === '$setPristine') {
              if (activeTab.value === 'describe_capture') {
                $ctrl.stepDescribeForm.$setPristine();
                $ctrl.captureForm.$setPristine();
              } else if (activeTab.value === 'assign') {
                $ctrl.assignForm.$setPristine();
              } else if (activeTab.value === 'set-deadline') {
                $ctrl.deadlineForm.$setPristine();
              } else if (activeTab.value === 'logic') {
                $ctrl.logicForm.$setPristine();
              } else if (activeTab.value === 'settings') {
                $ctrl.settingsForm.$setPristine();
              }
            }
          }

          /**
           * @ngdoc methods
           * @name openPowerBuilder
           *
           * @description power builder modal open method handler
           */
          function openPowerBuilder() {
            if ($ctrl.isProcessHavingDocumentType) {
              $ctrl.isPowerBuilderOpen = true;
              $rootScope.$emit('DOCUMENT_EDITOR:OPEN');
            } else {
              StepService.openPowerBuilder($ctrl.process, $ctrl.process.steps.data, 'blueprint', $ctrl.availableUsers, $ctrl.orgGroups)
                .result.then(function (result) {
                  if (result.type === 'power-builder') {
                    _.forEach(result.steps, function (step) {
                      onStepCreated(step);
                      $rootScope.$emit('STEP:LINK_STAGE', { step: step, newStep: true });
                    });
                  }
                  if (result.type === 'power-assigner') {
                    _.forEach(result.steps, function (step) {
                      $rootScope.$emit('STEP:UPDATED', step);
                    });
                  }
                  if (result.type === 'power-deadline') {
                    _.forEach(result.steps, function (step) {
                      $rootScope.$emit('STEP:UPDATED', { step: step, updateDeadline: true });
                    });
                  }
                  $rootScope.$emit('ACTIVITY:UPDATES', { auditableId: $ctrl.process.id, auditableType: 'checklist' });
                }, function () {
                  $log.info('Modal is cancelled');
                });
            }
          }

          /**
           * @ngdoc method
           * @name isBPEDetailDrawerOpen
           */
          function isBPEDetailDrawerOpen() {
            return RunDetailService.isDrawerOpen();
          }

          /**
           * @ngdoc method
           * @name onStepCreated
           * @param {*} step
           *
           * @description on step created method
           */
          function onStepCreated(step) {
            if (!step) {
              return;
            }
            $ctrl.process.steps.data.push(step);
            $rootScope.$emit('STEPS:ADD', { process: $ctrl.process });
            $rootScope.$emit("STEP:UPDATE_DEADLINE", step);
            if ($ctrl.process.steps.data.length === 1) {
              StepService.openRightPaneOnFirstStepCreate($ctrl.process, step);
            }
          }

          /**
           * @ngdoc method
           * @name migrateOldSnippetTag
           * @description change old tag snippet to document tag
           * @param {Integer} tag
           */
          function migrateOldSnippetTag(tag) {
            tag.title = BLUEPRINT_TYPE.DOCUMENT;
            TagsService.updateTag({
              id: tag.id,
              title: tag.title
            }).then(function () {
              var index = _.findIndex($ctrl.process.tags.data, { 'tag_id': tag.id });
              if (index >= 0 && $ctrl.process.tags.data[index].title === BLUEPRINT_TYPE.SNIPPET) {
                $ctrl.process.tags.data[index].title = BLUEPRINT_TYPE.DOCUMENT;
              }
            }, function () {
              $log.error("migrate old snippet tag error");
            });
          }

          /**
           * @ngdoc method
           * @name getLastUpdatedDate
           * @description get latest updated date
           */
          function getLastUpdatedDate() {
            if ($ctrl.process.steps_count > 0) {
              var maxDate = _.orderBy($ctrl.process.steps.data, 'last_updated', 'desc'), maxOfLastUpdated = _.max([maxDate[0].last_updated, $ctrl.process.last_updated_unformatted]);
              $ctrl.process.last_updated = maxOfLastUpdated;
              $ctrl.lastUpdatedFormatted = DateUtils.toTimezone(maxOfLastUpdated).value();
            } else {
              $ctrl.lastUpdatedFormatted = DateUtils.toTimezone($ctrl.process.last_updated).value();
            }
          }

          function onIconSelected(icon) {
            $ctrl.process.icon = icon;
            ProcessService.update({
              id: $ctrl.process.id
            }, $ctrl.process).then(function (res) {
              $rootScope.$emit('PROCESS:UPDATED', { process: res });
            });
          }

          function getLastSavedTime() {
            return $filter('amTimeAgo')(DateUtils.toTimezone($ctrl.process.last_updated).value().subtract(3, 'seconds'));
          }

          /**
           * @name shareBPLink
           *
           * @description
           * Share BP link
           */
          function shareBPLink() {
            $uibModal.open({
              component: 'shareLink',
              windowClass: 'modal-fade fade share-link-modal',
              resolve: {
                link: function () {
                  return $location.absUrl();
                },
                titleTranslation: function () {
                  return 'process.label.share_link_title';
                }
              }
            });
          }

          /**
           * @name trackEvent
           * @param {*} eventProps
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventProps) {
            var defaultProps = {
              category: Helper.eventTrackingCategories['blueprint'],
              name: $ctrl.process.title,
              blueprintId: $ctrl.process.id
            };
            angular.extend(defaultProps, eventProps);
            $analytics.eventTrack('Click', defaultProps);
          }

          function setScrollContainer() {
            if ($ctrl.process.type === BLUEPRINT_TYPE.DOCUMENT) {
              if ($ctrl.activeTab === $ctrl.BP_VIEW.READ) {
                angular.element('.app-body').removeClass('compact-step-snippet-active');
              } else if ($ctrl.activeTab === $ctrl.BP_VIEW.EDIT) {
                angular.element('.app-body').addClass('compact-step-snippet-active');
              }
            }
          }

          function tocMenuItemClick(menu) {
            $state.transitionTo($state.current, angular.extend($stateParams, { section: menu.nativeElement.getAttribute('id') }), { notify: false });
            $timeout(function () {
              $ctrl.tocFocusElementId = menu.nativeElement.id;
            });
          }

          function toggleTocView(e, flag) {
            $ctrl.showTOC = flag ? false : !$ctrl.showTOC;
            $ctrl.froalaViewBusy = false;
            if (!$ctrl.showTOC && $ctrl.tocFocusElementId) {
              $ctrl.tocFocusElementId = void 0;
              $state.transitionTo($state.current, angular.extend($stateParams, { section: void 0 }), { notify: false });
            }
          }

          /**
           * @name closePrintOptionsPane
           *
           * @description
           * method to handling print options view(show) or not(hide)
           */
          function closePrintOptionsPane() {
            $ctrl.showPrint = false;
            $state.transitionTo($state.current, angular.extend($stateParams, { print: void 0 }), { notify: false });
          }

          function calculatePercentage() {
            var filledFields = _.reduce($ctrl.prerunValues,
              function (acc, value) {
                if (!Helper.isObjectEmpty(value)) {
                  acc++;
                }
                return acc;
              }, 0),
              prerunLength = _.get($ctrl.process, 'prerun', []).length;
            $ctrl.progressBarModel = {
              string: '(' + filledFields + '/' + prerunLength + ')',
              style: { width: ((filledFields / prerunLength).toFixed(2) * 100) + '%' }
            };
            if ((filledFields === prerunLength) && !$ctrl.hasBeenShow) {
              $timeout(function () {
                $ctrl.allFilled = true;
              }, 800).then(function () {
                $timeout(function () {
                  $ctrl.allFilled = false;
                  $ctrl.hasBeenShow = true;
                }, 500);
              });
            }
          }

          function onEditPreviewChanged(isEdit) {
            blockUI.start();
            isEdit ? toggleProcessTab('editBP') : toggleProcessTab('readBP');
          }

          //Check if launch button is disabled
          function isLaunchBtnDisabled() {
            var procedureCheck = $ctrl.isProcessHavingProcedureType && (!$ctrl.process.steps.data.length || !$ctrl.havePermissionToLaunch),
              othersCheck = ($ctrl.isProcessHavingDocumentType || $ctrl.isProcessHavingFormType) && (!$ctrl.process.prerun.length || !$ctrl.havePermissionToLaunch);

            return ($ctrl.isDocsPlan || procedureCheck || othersCheck);
          }

          /**
          * @ngdoc method
          * @name onPlayClick
          * @param {*} data
          * @description Play Blueprint Explanation Video
          * @returns {object} video
          */
          function onPlayClick(data) {
            $uibModal.open({
              component: 'explanationVideoModal',
              windowClass: 'explanation-video-modal',
              resolve: {
                data: function () {
                  return data;
                }
              }
            });
          }

          //Handle breadcrumb click
          function onBreadcrumbClick(e, folder) {
            if (e) {
              e.stopPropagation();
            }
            $state.go('process.templates', {
              status: 'folder',
              page: 1,
              folder_id: folder.id
            });
            $rootScope.$emit('FOLDER_CLICK:VIEW_BLUEPRINT', { folder: folder });
          }

          function openAutomations(automation) {
            blockUI.start();
            $ctrl.activeTab = $ctrl.BP_VIEW.AUTOMATIONS;
            $ctrl.isEditPreview = false;
            changeProcessTab($ctrl.activeTab);
            setDefaultView($ctrl.activeTab);
            $ctrl.showTOC = false;
            $ctrl.froalaViewBusy = false;
            $ctrl.tocFocusElementId = void 0;
            $ctrl.metadata = {};
            $ctrl.selectedItem = void 0;
            DOMService.scrollViewToTop(DOM.MASTER_VIEW.ELEMENT);
            setScrollContainer();
            $state.transitionTo($state.current, angular.extend($stateParams, {
              step: void 0,
              section: void 0,
              view: 'automations',
              automationId: automation ? automation.id : void 0
            }), { notify: false });
            toggleProcessSubHeaderIndex(false);
            $timeout(function () {
              blockUI.stop();
            }, 500);
          }

          //Window resize handler
          function onWindowResize() {
            var processHeaderElem = angular.element(document.getElementsByClassName('process-header')),
              processSubHeaderElem = angular.element(document.getElementsByClassName('compact-sub-nav')),
              mainSectionElem = angular.element(document.getElementsByClassName('process-timeline')),
              tocContentElem = angular.element(document.getElementsByClassName('toc-content')),
              docEditorElem = angular.element(document.getElementsByClassName('document-editor')),
              frBoxElem = angular.element(document.getElementsByClassName('fr-box')),
              frMainElem = angular.element(document.getElementsByClassName('fr-element')),
              frToolbarElem = angular.element(document.getElementsByClassName('fr-toolbar')),
              frFooterElem = angular.element(document.getElementsByClassName('fr-footer-element')),
              codeMirrorElem = angular.element(document.getElementsByClassName('CodeMirror')),
              documentTasksElem = angular.element(document.getElementsByClassName('document-editor-content')),
              contentContainer = angular.element(document.getElementById('content')),
              isMasquerading = contentContainer.length ? contentContainer.get(0).classList.contains('masquerading') : false,
              isFullscreen = frBoxElem.length ? frBoxElem.get(0).classList.contains('fr-fullscreen') : false,
              fixedHeight = isMasquerading ? 100 : 60;
            if (processHeaderElem && processSubHeaderElem && fixedHeight) {
              var heightValue = Math.ceil(Number(processHeaderElem.get(0).clientHeight) + Number(processSubHeaderElem.get(0).clientHeight + fixedHeight)),
                height = 'calc(100vh - ' + heightValue + 'px)';
            }
            if (mainSectionElem) {
              mainSectionElem.get(0).style.height = isFullscreen ? '100vh' : height;
            }
            if ($ctrl.activeTab === $ctrl.BP_VIEW.READ && tocContentElem) {
              tocContentElem.get(0).style.height = height;
              toggleProcessSubHeaderIndex(false);
            }
            if ($ctrl.isProcessHavingDocumentType) {
              if (tocContentElem && $ctrl.activeTab === $ctrl.BP_VIEW.EDIT) {
                tocContentElem.get(1) ? tocContentElem.get(1).style.height = height : (tocContentElem.get(0) ? tocContentElem.get(0).style.height = height : angular.noop());
              }
              if (documentTasksElem && documentTasksElem.get(0)) {
                documentTasksElem.get(0).style.height = height;
              }
              if (docEditorElem && docEditorElem.get(0)) {
                docEditorElem.get(0).style.height = isFullscreen ? '100vh' : height;
                if (frToolbarElem && frFooterElem && frMainElem) {
                  var frMainHeight = 'calc(100vh - ' + Math.ceil(Number((isFullscreen ? 0 : heightValue) + frToolbarElem.get(0).clientHeight + frFooterElem.get(0).clientHeight)) + 'px)';
                  $rootScope.$emit('FROALA:SET_WRAPPER_HEIGHT', { height: frMainHeight });
                  if (codeMirrorElem.length) {
                    codeMirrorElem.get(0).style.height = frMainHeight;
                  }
                }
              }
            }
          }

          function updateAutomation() {
            $ctrl.process.last_updated = DateUtils.toUTC(moment());
          }

          // Toggle sub-nav z-index
          function toggleProcessSubHeaderIndex(isZIndexLow) {
            var processSubHeaderElem = angular.element(document.getElementsByClassName('compact-sub-nav'));
            if (processSubHeaderElem && processSubHeaderElem.get(0) && processSubHeaderElem.get(0).classList) {
              isZIndexLow ? processSubHeaderElem.get(0).classList.remove('increase-z-index') : processSubHeaderElem.get(0).classList.add('increase-z-index');
            }
          }

          // Handle folder dropdown click
          function handleFolderDropdownClick(isOpen) {
            if ($ctrl.selectedItem) {
              return;
            }
            toggleProcessSubHeaderIndex(isOpen);
          }

          function updateProcess() {
            var withStr = 'steps,steps.captures,steps.threads,tags,folder,steps.tags,steps.roles,assets,stages';
            ProcessService.get({
              id: $ctrl.processId,
              with: withStr + ($ctrl.isGuest ? ',guest_watchers.watcher' : ',member_watchers.watcher'),
              skipNotFound: true
            }).then(function (res) {
              $ctrl.process = res.data;
            });
          }

          //Set active category for step edit
          function setActiveCategory(category) {
            if (!category) {
              $ctrl.activeCategory = $ctrl.stepCategories[0];
            }
            $ctrl.activeCategory = category;
          }

          //Setup step edit categories
          function setupStepCategories() {
            var step = $ctrl.selectedItem.step ? $ctrl.selectedItem.step : $ctrl.process.steps.data[0];
            if (!$ctrl.process || !step) {
              return;
            }
            _.map($ctrl.stepCategories, function (category) {
              if (category.canHaveValue) {
                angular.extend(category, {
                  tabValue: category.key === 'fields' ? step.captures.length : CompactStepService.getAffectingAutomations($ctrl.process, step).length
                })
              }
            });
            setActiveCategory($ctrl.stepCategories[0]);
          }

          //Handle category change
          function onCategoryChange(category) {
            var tab = category.key !== 'basics' ? category : {};
            onTabChanged(tab, category);
          }

          // Handle settings dropdown change
          function handleSettingsChange(tab) {
            $ctrl.selectedSettingsTab = tab;
            toggleBPEDetail();
          }

          // Handle more dropdown change
          function handleMoreChange(tab) {
            switch (tab.id) {
              case 'print':
                toggleProcessTab('readBP', true)
                break;
              case 'duplicate':
                $ctrl.duplicateBPE($ctrl.process, $ctrl.orgGroups);
                break;
              case 'archive':
                archiveProcess();
                break;
              default:
                break;
            }
          }

          /**
           * @ngdoc method
           * @name onMoveToFolder
           * @param {*} process
           * @param {*} folder
           *
           * @description
           * move blueprint to a folder
           */
          function onMoveToFolder(process, folder) {
            if (!Helper.checkAccessAuthority(false))
              return;
            if (_.get(folder, 'id') !== _.get(process, 'folder.data.id')) {
              blockUI.start();
              process.folder_id = _.get(folder, 'id');
              ProcessService.update({
                id: process.id,
                skipNotFound: true
              }, process).then(function (res) {
                blockUI.stop();
                getAllParentData(process.folder_id);
                Growl.clearAllMessages('global');
                growl.success($filter('translate')('runs.messages.folder.movedToFolder', {
                  bpName: res.data.title
                }), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, function (error) {
                blockUI.stop();
                if (error.status === 404) {
                  Growl.clearAllMessages('global');
                  growl.error($filter('translate')('steps.messages.bluePrintNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                }
              });
            } else {
              Growl.clearAllMessages('global');
              $timeout(function () {
                growl.warning($filter('translate')('runs.messages.folder.alreadyOnFolder', {
                  bpName: _.get(process, 'title'),
                  folderName: _.get(folder, 'name')
                }), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 0);
            }
          }

          // Remove from folder & move to uncategorized
          function onRemoveFromFolder(process) {
            if (!Helper.checkAccessAuthority(false))
              return;
            blockUI.start();
            process.folder_id = null;
            ProcessService.update({
              id: process.id
            }, process).then(function (res) {
              blockUI.stop();
              $ctrl.breadcrumb = null;
              growl.success($filter('translate')('runs.messages.folder.removedFromFolder', {
                bpName: res.data.title
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            });
          }

          processStepsUpdatedHandler = $rootScope.$on('PROCESS:STEP_UPDATED', function () {
            updateProcess();
          });

          // step updated event handler
          var unRegisteredEventStepUpdated = $rootScope.$on('STEP:UPDATED', function (e, data) {
            var stepData = data.step || data,
              updatedStepIndex = _.findIndex($ctrl.process.steps.data, { id: stepData.id });
            if (stepData && updatedStepIndex > -1) {
              angular.extend($ctrl.process.steps.data[updatedStepIndex], {
                assignees: stepData.assignees || [],
                guests: stepData.guests || [],
                groups: stepData.groups || [],
                owner: stepData.owner || [],
                deadline: stepData.deadline
              });
            }
            if ($ctrl.stepDescribeForm.$pristine) {
              _.extend($ctrl.process.steps.data[updatedStepIndex], stepData);
            }
            getLastUpdatedDate();
            $rootScope.$emit('PROCESS:UPDATED', { process: $ctrl.process, updateDeadline: data.updateDeadline });
          });

          processTypeWatcher = $scope.$watch('$ctrl.process.type', function () {
            setScrollContainer();
          });

          prerunWatcher = $scope.$watch('$ctrl.prerunValues', function (newValue, oldValue) {
            if (newValue === oldValue) {
              return;
            }
            calculatePercentage();
          }, true);

          var rightPaneDocumentBuilderCloseHandler = $rootScope.$on('DOCUMENT_EDITOR:CLOSE', function () {
            $ctrl.isPowerBuilderOpen = false;
            onWindowResize();
          });

          var rightPaneDocumentBuilderOpenHandler = $rootScope.$on('DOCUMENT_EDITOR:OPEN', function () {
            $ctrl.isPowerBuilderOpen = true;
            onWindowResize();
          });

          var stepPositionUpdated = $rootScope.$on("STEP:UPDATE_STEP_POSITION", function () {
            var withStr = 'steps,steps.captures,steps.threads,tags,folder,steps.tags,steps.roles,assets,stages';
            $q.all([
              ProcessService.get({
                id: $ctrl.processId,
                with: withStr + ($ctrl.isGuest ? ',guest_watchers.watcher' : ',member_watchers.watcher'),
                skipNotFound: true
              }),
              StepService.getAllDeadlines({
                id: $ctrl.processId,
                in_working_days: false,
                skipNotFound: true
              }),
              ProcessService.getPermission({
                id: $ctrl.processId,
                skipNotFound: true
              })
            ]).then(function (res) {
              $ctrl.highestDeadline = ProcessService.getProcessDeadline(res[1].data, 'deadline_unformatted');
              var steps = res[0].data.steps.data;
              _.forEach(steps, function (step) {
                var deadline = _.find(res[1].data, {
                  step_id: step.id
                });
                if (!_.isUndefined(deadline)) {
                  step.absolute_deadline = deadline.deadline_unformatted;
                  step.deadlineAsHumanized = humanizeDeadline(step.absolute_deadline).replace('in', '');
                  step.deadlineAsString = StepService.getStepDeadlineAsStr(steps, step);
                }
              });
              $ctrl.process.can_edit_perms = res[2].data;
              $ctrl.process = res[0].data;              
            });
          });

          //on step added handler
          var onStepAddedHandler = $rootScope.$on('STEPS:ADD', function () {
            getLastUpdatedDate();
          });

          contentLanguageChangedHandler = $rootScope.$on('CONTENT_LANGUAGE_CHANGED', function () {
            $ctrl.documentTranslateTo = TranslationService.getMyContentLanguage();
          });

          if ($rootScope.userState === USER_STATE.MEMBER) {
            unRegisteredPreferenceWatcherHandler = $rootScope.$watchCollection('identity.preferences', function (value) {
              if (!value || !value.length) {
                return;
              }
              $ctrl.documentTranslateTo = TranslationService.getMyContentLanguage();
            });
          } else {
            unRegisteredPreferenceWatcherHandler = $rootScope.$watchCollection('identity.guest.preferences', function (value) {
              if (!value || !value.length) {
                return;
              }
              $ctrl.documentTranslateTo = TranslationService.getMyContentLanguage();
            });
          }

          automationAddHandler = $rootScope.$on('AUTOMATION:ADD', function (e, data) {
            updateAutomation();
          });

          automationUpdateHandler = $rootScope.$on('AUTOMATION:UPDATE', function (e, data) {
            updateAutomation();
          });

          automationDeleteHandler = $rootScope.$on('AUTOMATION:DELETE', function (e, data) {
            updateAutomation();
          });

          openAutomationPage = $rootScope.$on('OPEN_AUTOMATION_PAGE', function (e, data) {
            openAutomations(data ? data.automation : void 0);
          });

          //Handle right pane open and close
          rightPaneOpenHandler = $rootScope.$on('RIGHT_PANE:OPEN', function (e, data) {
            onWindowResize();
            toggleProcessSubHeaderIndex(!_.isEmpty(data));
          });

          rightPaneCloseHandler = $rootScope.$on('RIGHT_PANE:CLOSE', function (e, data) {
            onWindowResize();
            toggleProcessSubHeaderIndex(_.isEmpty(data));
          });

          froalaFullscreenHandler = $rootScope.$on('FROALA:FULLSCREEN_TOGGLE', function (e, data) {
            onWindowResize();
          });

          froalaHtmlHeightHandler = $rootScope.$on('FROALA:SHOW_HTML_TOGGLE', function () {
            onWindowResize();
          });

          selectedItemWatcherHandler = $scope.$watch('$ctrl.selectedItem', function (value) {
            if (value) {
              setupStepCategories();
            }
          })
          folderSelectedWatcher = $rootScope.$on('FOLDER:CLICK', function (e, data) {
            onMoveToFolder(data.blueprint, data.folder);
          });

          folderRemovedWatcher = $rootScope.$on('FOLDER:REMOVE', function (e, data) {
            onRemoveFromFolder(data.blueprint);
          });

          fieldChangesWatcher = $rootScope.$on('STEP:FIELD_CHANGED', function (e, data) {
            var index = _.findIndex($ctrl.stepCategories, { key: 'fields' });
            if (index > -1) {
              $ctrl.stepCategories[index].tabValue = data.step.captures.length;
            }
          });

          //controller ends
        }
    });
})();
