/**
 * @ngdoc Component
 * @name tallyfy.run.component.runDashboard
 * @module tallyfy.process
 *
 * @description
 * A component to manage process edit view
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.run')
    .component('runDashboard', {
      templateUrl: 'app/modules/runs/dashboard/runs-dashboard.html',
      controller:
        /*@ngInject*/
        function (Growl, RunRepository, _, blockUI, $filter, store, UsersService, $stateParams, AccountService, $log, $rootScope, $q, ProgressBar, RunsDashboardService,
          TasksFilterService, preferencesStore, $timeout, TFY_EVENTS, $state, Helper, AuthPlan, RunsService, USER_STATE, FolderService, TagsService, BLUEPRINT_TYPE, $window) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('runDashboard'),
            isCloseImage = false,
            currentPage = $state.params.page ? parseInt($state.params.page) : 1,
            body = angular.element('.app-body'),
            windowElem = angular.element($window),
            dashboardHeightTimeoutHandler;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.availableUsers = [];
          $ctrl.filterConfig = {
            paramObjects: {},
            metadata: {
              total: 0
            }
          };
          $ctrl.filters = {};

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.archiveRun = archiveRun;
          $ctrl.unArchived = unArchived;
          $ctrl.closeIntroImage = closeIntroImage;
          $ctrl.onFavoriteUpdated = onFavoriteUpdated;
          $ctrl.getEmptyProcessMessage = getEmptyProcessMessage;
          $ctrl.launchNewProcess = launchNewProcess;
          $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
          $ctrl.reSizeBoxWidth = reSizeBoxWidth;
          $ctrl.confirmPermanentDeleteRun = confirmPermanentDeleteRun;
          $ctrl.getTagTextColor = TagsService.isTagTextColor;

          $ctrl.filterChanges = filterChanges;

          /**
           * public properties
           */
          $ctrl.runs = [];
          $ctrl.userPreferences = [];
          $ctrl.listPager = {
            total: 0,
            per_page: 24,
            current_page: currentPage,
            onPageChanged: function (current_page) {
              Growl.clearAllMessages('global');
              this.current_page = current_page;
              fetchRuns();
            }
          };
          $ctrl.isProcessIntroView = false;
          $ctrl.isLoader = true;
          $ctrl.isRunsSidebarShowSmallScreen = false;

          windowElem.on('resize', onWindowResize);

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            moveScrollToTop();
            body.addClass('run-dashboard');
            $ctrl.isGuest = ($rootScope.userState === USER_STATE.GUEST);
            $ctrl.filters = RunsDashboardService.getFilterConfig();
            blockUI.start();
            if ($ctrl.isGuest) {
              $ctrl.listPager.current_page = 1;
              fetchRuns();
            } else {
              $ctrl.organization = _.get($rootScope.identity, 'default_organization', {});
              $ctrl.isBusy = true;
              var resources = [getFolders(), getTagsList(), getUsers(), getGroups()];
              $q.all(resources).then(function (res) {
                $ctrl.foldersList = res[0].data || [];
                $ctrl.allTags = _.sortBy(res[1].data, 'title');
                $ctrl.availableUsers = UsersService.getBilledUsers(res[2]);
                $ctrl.orgGroups = res[3];
                $ctrl.isBusy = false;
                onWindowResize();
              });
            }
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            body.removeClass('run-dashboard');
            dashboardHeightTimeoutHandler ? $timeout.cancel(dashboardHeightTimeoutHandler) : angular.noop();
            windowElem.off('resize', onWindowResize);
          }

          function filterChanges(firstChange, filterObjectChanged, savePreferenceOnly) {
            if (firstChange) {
              fetchRuns();
            } else {
              if (!$ctrl.isGuest) {
                savePreferences(filterObjectChanged.preference);
              }
              if (!savePreferenceOnly) {
                fetchRuns();
              }
            }
          }

          function getTagsList() {
            var defer = $q.defer(), params = { page: 1, per_page: 999, action: 'tags' };
            TagsService.get(params)
              .then(
                function (response) {
                  defer.resolve(response);
                },
                function (err) {
                  defer.reject(err);
                }
              );
            return defer.promise;
          }

          function getFolders() {
            var defer = $q.defer(),
              params = {
                pagination: false,
                sort: 'title',
                with: 'children',
                folder_type: 'run'
              };
            FolderService.getTaskOrRunFolders(params)
              .then(
                function (res) {
                  defer.resolve(res);
                },
                function (err) {
                  defer.reject(err);
                }
              );
            return defer.promise;
          }

          /**
           * @ngdoc method
           * @name reSizeBoxWidth
           * @description
           * resize box width when html load.
           */
          function reSizeBoxWidth() {
            if ($ctrl.isLoader) {
              return;
            }
            $timeout(function () {
              $rootScope.$broadcast(TFY_EVENTS.GRIDLY.RE_SIZE_BOX_WIDTH);
            }, 350);
          }

          function savePreferences(params) {
            var defer = $q.defer();
            AccountService.setUserPreference(params).then(
              function (response) {
                preferencesStore.updatePreferences(response.data);
                defer.resolve(response);
              }, function (error) {
                $log.error(error);
                defer.reject(error);
              }
            );
            return defer.promise;
          }

          function moveScrollToTop() {
            angular.element(document.querySelector('.app-body'))[0].scrollTop = 0;
          }

          /**
           * @function
           * @name getGridRuns
           * @description Get run records
           * @param reqParams
           */
          function getGridRuns(reqParams) {
            $rootScope.$broadcast(TFY_EVENTS.GRIDLY.REMOVE_GRIDLY_ON_CLICK);
            showLoader(true);
            if ($ctrl.isGuest) {
              RunRepository.getGuestRunData(reqParams)
                .then(function (res) {
                  $ctrl.runs = res.data;
                  if (!_.isEmpty(res.meta)) {
                    $ctrl.listPager.total = res.meta.pagination.total;
                    $ctrl.filterConfig.metadata.total = TasksFilterService.formatCount($ctrl.listPager.total, true);
                  }
                  showLoader(false);
                  updateIntroView();
                  if (!ProgressBar.isActiveProgress() && $ctrl.runs.length > 0) {
                    $rootScope.$broadcast(TFY_EVENTS.GRIDLY.RE_INIT_ANIMATION, { isResetGridly: true });
                  }
                  reSizeBoxWidth();
                }, function () {
                  showLoader(false);
                });
            } else {
              RunRepository.getRunsData(reqParams)
                .then(function (res) {
                  $ctrl.runs = res.data;
                  if (!_.isEmpty(res.meta)) {
                    $ctrl.listPager.total = res.meta.pagination.total;
                    $ctrl.filterConfig.metadata.total = TasksFilterService.formatCount($ctrl.listPager.total, true);
                  }
                  showLoader(false);
                  updateIntroView();
                  if (!ProgressBar.isActiveProgress() && $ctrl.runs.length > 0) {
                    $rootScope.$broadcast(TFY_EVENTS.GRIDLY.RE_INIT_ANIMATION, { isResetGridly: true });
                  }
                  reSizeBoxWidth();
                }, function () {
                  showLoader(false);
                  updateIntroView();
                  growl.error('global.error.SERVER_ERROR', {
                    variables: {
                      'reason': 'general_api_error'
                    }, referenceId: 'global', disableIcons: true, disableCloseButton: true
                  });
                });
            }
          }

          /**
           * @function
           * @name archiveRun
           * @description Archive run
           * @param {*} run
           */
          function archiveRun(run) {
            if (!Helper.checkAccessAuthority())
              return;
            Growl.clearAllMessages('global');
            var oldRuns = angular.copy($ctrl.runs),
              index = _.findIndex($ctrl.runs, { 'id': run.id });

            $timeout(function () {
              if (index >= 0) {
                updateRuns(index);
                growl.success($filter('translate')('runs.dashboard.runAction.archived'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true,
                  variables: {
                    method: undoArchived,
                    param: { 'run': run, 'oldRuns': oldRuns },
                    linkText: $filter('translate')('runs.dashboard.undo')
                  }
                });
              }
            }, 450);
            deleteRunData(run.id, oldRuns);
          }

          /**
           * @function
           * @name deleteRunData
           * @description delete run
           * @param {*} runId
           * @param {*} oldRuns
           */
          function deleteRunData(runId, oldRuns) {
            RunRepository.deleteRunsData({
              id: runId
            }).then(function () { }, function (error) {
              $ctrl.runs = oldRuns;
              growl.error(error.message, {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            });
          }

          /**
           * @function
           * @name updateRuns
           * @description update Runs
           * @param value
           * value can be array or index based on that it will perform action
           */
          function updateRuns(value) {
            if (!_.isNumber(value)) {
              $ctrl.runs = value;
              $ctrl.listPager.total = $ctrl.listPager.total + 1;
            } else {
              $ctrl.runs.splice(value, 1);
              $ctrl.listPager.total = $ctrl.listPager.total - 1;
            }
            $ctrl.filterConfig.metadata.total = TasksFilterService.formatCount($ctrl.listPager.total, true);
          }

          /**
           * @function
           * @name undoArchived
           * @description undo archived process
           * @param {object} param
           */
          function undoArchived(param) {
            if (!param) {
              return;
            }
            Growl.clearAllMessages('global');
            $rootScope.$emit(TFY_EVENTS.GRIDLY.UNDO_ANIMATION);
            reSizeBoxWidth();
            updateRuns(param.oldRuns);
            unArchived(param.run, true);
          }

          function fetchRuns() {
            if (Helper.isObjectEmpty($ctrl.filterConfig.paramObjects)) {
              return;
            }
            $ctrl.filterConfig.metadata.total = 0;
            document.getElementsByClassName('runs-dash-view')[0].scrollIntoView();
            var requestParams = getRequestParams();
            if ($ctrl.filterConfig.paramObjects.viewType.value === 'grid') {
              getGridRuns(requestParams);
            } else {
              $ctrl.tableModeFilterParams = _.omit(requestParams, ['page', 'per_page', 'with']);
              showLoader(false);
            }
            $state.go($state.current, angular.extend($stateParams, {
              filter: requestParams.filter_type,
              folder: requestParams.folder,
              tags: requestParams.tag_ids && requestParams.tag_ids.length ? requestParams.tag_ids.join(',') : void 0,
              sortBy: requestParams.sort,
              page: requestParams.page
            }), { notify: false, location: 'replace' });
            blockUI.stop();
          }

          /**
           * @function
           * @name getRequestParams
           * @description Make param object for getting run records
           * @private
           *
           * @returns void
           */
          function getRequestParams() {
            var requestParams = {},
              sort = $ctrl.filterConfig.paramObjects.sortObj,
              type = $ctrl.filterConfig.paramObjects.typeObj;
            requestParams.with = $ctrl.isGuest ? 'tasks_meta,tags,guest_watchers.watcher' : 'tasks_meta,folders,tags,member_watchers.watcher';
            requestParams.sort = sort.value;
            requestParams.per_page = $ctrl.listPager.per_page;
            requestParams.page = $ctrl.listPager.current_page;
            requestParams.type = _.get(type, 'id', '');
            requestParams.checklist = _.get($stateParams, 'checklist');

            if (!$ctrl.isGuest) {
              angular.extend(requestParams, getRequestParamsForFilter() || {});
            }

            return requestParams;
          }

          function getRequestParamsForFilter() {
            var params = {},
              filter = $ctrl.filterConfig.paramObjects.filterObj,
              tags = $ctrl.filterConfig.paramObjects.tagsObj,
              folder = $ctrl.filterConfig.paramObjects.folderObj,
              sort = $ctrl.filterConfig.paramObjects.sortObj;
            var record = _.find(filter.criteria, { field: 'folder' });
            if (record) {
              if (!folder.id) {
                _.set($ctrl.filterConfig, 'paramObjects.folderObj.id', record.value);
                angular.extend(folder, { id: record.value });
              }
              if (folder.id && !folder.name) {
               _.set($ctrl.filterConfig, 'paramObjects.folderObj', {}); 
              }
              $state.transitionTo($state.current, angular.extend($stateParams, { folder: _.get(record, 'value', void 0) }), { notify: false });
            }

            if (filter && _.isObject(filter)) {
              filter.criteria = filter.criteria || [];
              var tagFilter = filter.criteria.filter(function (val) {
                 return val.field == 'tag';
               }),
               nonTagFilters = filter.criteria.filter(function (val) {
                 return val.field != 'tag';
               }),
               taskTypeFilters = filter.criteria.filter(function (val) {
                 return !!(val.task);
               });
              if (taskTypeFilters.length > 0) {
                  params.status = taskTypeFilters[0].task;
              }
              if (tagFilter.length) {
                if (tagFilter.length == 1) {
                  params.tag = tagFilter[0].value;
                } else {
                  params.tag_ids = _.map(tagFilter, function (tag) {
                    return tag.value;
                  });
                }
              }
              if (nonTagFilters.length) {
                _.forEach(nonTagFilters, function (elem) {
                  if (elem.field == 'status' && elem.value == 'untagged') {
                    params.untagged = true;
                  } else {
                    params[elem.field] = params[elem.field] ? (params[elem.field] + ',' + elem.value) : elem.value;
                  }
                });
              }
              if (!Helper.isObjectEmpty(filter)) {
                filter.criteria && filter.criteria.length === 1 ? params.filter_type = 'all' : params.filter_type = filter.id;
              }

              // Set active filter procedure view
              if (filter.id === BLUEPRINT_TYPE.PROCEDURE) {
                params.type = BLUEPRINT_TYPE.PROCEDURE;
              }

              // Set active filter forms view
              if (filter.id === BLUEPRINT_TYPE.FORM) {
                params.type = BLUEPRINT_TYPE.FORM;
              }

              // Set active filter documents view
              if (filter.id === BLUEPRINT_TYPE.DOCUMENT) {
                params.type = BLUEPRINT_TYPE.DOCUMENT;
              }
            }

            if (tags && tags.length) {
              if (tags.length == 1) {
                params.tag = tags[0].id
              } else {
                params.tag_ids = _.map(tags, function (tag) {
                  return tag.id;
                });
              }
            }

            if (folder) {
              params.folder = folder.id;
            }

            if (sort) {
              params.sort = sort.value;
            }

            if ((params.status) && (params.status === 'archived')) {
              if (params.filter_type === 'any') {
                params.archived = true;
              } else {
                params.archived = 'only';
              }
            }
            return params;
          }

          /**
           * @function
           * @name unArchived
           * @description unArchived run
           * @param {Object} run
           *  if isLoading parameter is true then not display loader
           *  if isLoading parameter is false then display loader
           */
          function unArchived(run) {
            if (!Helper.checkAccessAuthority())
              return;
            angular.element(document).find('.flex-container').removeClass('gridly');
            Growl.clearAllMessages('global');
            RunRepository.activeRunsData({
              id: run.id
            }).then(function () {
              _.remove($ctrl.runs, { id: run.id });
              $timeout(function () {
                growl.success($filter('translate')('runs.dashboard.process.status'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 0);
            }, function () {
              showLoader(false);
            });
          }

          /**
           * @function
           * @name updateIntroView
           * @description  update $ctrl.isProcessIntroView
           * @returns void
           */
          function updateIntroView() {
            var isArchived = _.get($ctrl.requestParams, 'status') === 'archived';
            $ctrl.isProcessIntroView = $ctrl.runs.length < 3 && !isArchived && isViewedImage() && !isCloseImage;
            $log.debug('updateIntroView', $ctrl.isProcessIntroView);
          }

          /**
           * @function
           * @name isViewedImage
           * @description Get value "process_intro_viewed" slug from $rootScope
           * @returns {Boolean}
           */
          function isViewedImage() {
            var processIntro = _.find($rootScope.identity.preferences, { 'slug': 'process_intro_viewed' });
            return (_.isUndefined(processIntro) || processIntro.value === 'no');
          }

          /**
           * @function
           * @name closeIntroImage
           * @description close image when clicking on "X" icon
           * Store boolean value in user preferences
           */
          function closeIntroImage() {
            isCloseImage = true;
            savePreferences({
              slug: "process_intro_viewed",
              value: "yes"
            }).then(function (response) {
              $ctrl.isProcessIntroView = false;
              preferencesStore.updatePreferences(response.data);
            });
          }

          /**
           * @ngdoc method
           * @name showLoader
           * @param {*} isStart
           * @description show/hide ProgressBar
           * @returns void
           */
          function showLoader(isStart) {
            isStart ? ProgressBar.start() : ProgressBar.stop();
            $ctrl.isLoader = isStart;
            $ctrl.filterConfig.metadata.isBusy = isStart;
          }

          /**
           * @ngdoc method
           * @name onFavoriteUpdated
           * @param {*} run
           * @description update processes when user favorite
           *  and unfavorite process when 'Favorite' filter is active.
           */
          function onFavoriteUpdated(run) {
            if (_.get($ctrl.filterObj, 'id') === 'starred') {
              var index = _.findIndex(angular.copy($ctrl.runs), { 'id': run.id });
              if (index >= 0) {
                $ctrl.runs.splice(index, 1);
                $ctrl.filterConfig.metadata.total = $ctrl.filterConfig.metadata.total - 1;
                if (_.isEmpty(angular.copy($ctrl.runs))) {
                  $rootScope.$emit(TFY_EVENTS.GRIDLY.RE_INIT_ANIMATION, { isResetGridly: false, isUpdateHeight: true });
                }
              }
            }
          }

          /**
           * @ngdoc method
           * @name getEmptyProcessMessage
           * @description get default message when no any process
           */
          function getEmptyProcessMessage() {
            var filterType = _.get($ctrl.filterObj, 'id', '');
            if (filterType) {
              return (filterType > 0 ? 'process.no_process' : ('runs.dashboard.noProcessTitle.' + filterType));
            } else {
              return 'runs.dashboard.noProcessTitle.all';
            }
          }

          /**
           * @function
           * @name launchNewProcess
           * @description Launch New Process
           */
          function launchNewProcess() {
            if (!Helper.checkAccessAuthority() || !(!$ctrl.isLoader && !$ctrl.isDocsPlan && $ctrl.runs.length >= 0)) {
              return;
            }
            $state.go('process.templates');
          }

          /**
           * @ngdoc method
           * @name permanentlyDeleteRun
           * @param {Object} run
           * @description
           * Permanently delete Run
           */
          function permanentlyDeleteRun(run) {
            blockUI.start();
            RunRepository.permanentlyDeleteRun({
              id: run.id
            }).then(function () {
              blockUI.stop();
              var index = _.findIndex($ctrl.runs, { 'id': run.id });
              updateRuns(index);
              $rootScope.$emit(TFY_EVENTS.GRIDLY.RE_INIT_ANIMATION);
              growl.success($filter('translate')('template.permanentlyDeleteComponent.successMessage', {
                componentName: $filter('translate')('process.process')
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              $timeout(function () {
                Growl.clearAllMessages('global');
              }, 5000);
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name confirmPermanentDeleteRun
           * @param {Object} run
           * @description
           * open Run deletion confirmation modal
           */
          function confirmPermanentDeleteRun(run) {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            Growl.clearAllMessages('global');
            RunsService.openConfirmRunDeletion(run)
              .then(function () {
                permanentlyDeleteRun(run);
              });
          }

          function getUsers() {
            var defer = $q.defer();
            store.getUsers()
              .then(function (res) {
                defer.resolve(res);
              });
            return defer.promise;
          }

          function getGroups () {
            var defer = $q.defer();

            store.getLightweightGroups().then(function (res) {
              defer.resolve(res);
            });

            return defer.promise;
          }

          function onWindowResize () {
            dashboardHeightTimeoutHandler = $timeout(function () {
              var runFilterElem = angular.element(document.getElementById('run-filter')), mainElem = angular.element(document.getElementById('run-main'));
              if (runFilterElem && runFilterElem.get(0) && mainElem) {
                var contentContainer = angular.element(document.getElementById('content')),
                  isMasquerading = contentContainer.get(0).classList.contains('masquerading'),
                  fixedHeight = isMasquerading ? 100 : 60,
                  height = 'calc(100vh - ' + Math.ceil(Number(runFilterElem.get(0).clientHeight) + fixedHeight) + 'px)';
                if (mainElem) {
                  mainElem.get(0).style.height = height;
                }
              }
            }, 500);
          }

          //end of controller
        }
    });
})();
