(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name tallyfy.steps.service.StepService
   * @module tallyfy.steps
   *
   * @description
   * A service for steps
   *
   * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
   *
   * */
  angular
    .module('tallyfy.steps')
    .service('StepService', StepService);

  /*@ngInject*/
  function StepService(StepRepository, _, moment, $filter, $confirm, $q, $rootScope, $uibModal, DOMService, DOM, Growl, $log, Helper, AuthPlan, $sce, $timeout, CONFIG) {
    var self = this,
      growl = new Growl(),
      rule = [],
      updateStepPromise,
      modalContent = [
        {
          key: 'step',
          content: {
            body: 'steps.validateStepRule.body',
            header: 'steps.validateStepRule.header',
            subtel: 'steps.validateStepRule.subtel'
          }
        }, {
          key: 'capture',
          content: {
            body: 'steps.validateFormFieldRule.body',
            header: 'steps.validateFormFieldRule.header',
            subtel: 'steps.validateFormFieldRule.subtel'
          }
        }, {
          key: 'deadline',
          content: {
            body: 'steps.validateStepDeadline.body',
            header: 'steps.validateStepDeadline.header',
            subtel: 'steps.validateStepDeadline.subtel'
          }
        }, {
          key: 'kickoffform',
          content: {
            body: 'steps.validateFieldUpdate.body',
            header: 'steps.validateFieldUpdate.header',
            subtel: 'steps.validateFieldUpdate.subtel'
          }
        }
      ],
      stepGuestOwner = {
        allow_guest_owners: true,
        text: $filter('translate')('steps.assign.label.guestAssignLabel')
      },
      stepCategories = [
        {
          key: 'basics',
          name: $filter('translate')('steps.category.label.basics'),
          value: 'basics',
          class: '',
          eventText: 'Step - Basics',
          isEnable: true,
          tooltip: $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.formFields')), //Check and add for basics
          canHaveValue: false
        },
        {
          key: 'fields',
          name: $filter('translate')('steps.category.label.fields'),
          value: 'form_fields',
          class: '',
          eventText: 'Form Fields',
          isEnable: true,
          tooltip: $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.formFields')),
          canHaveValue: true
        },
        {
          key: 'automations',
          name: $filter('translate')('steps.category.label.automations'),
          value: 'automations',
          class: '',
          eventText: 'Automations',
          isEnable: true,
          tooltip: $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.automations')),
          canHaveValue: true
        },
        {
          key: 'advanced',
          name: $filter('translate')('steps.category.label.advanced'),
          value: 'settings',
          class: '',
          eventText: 'Advanced',
          isEnable: true,
          tooltip: $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.advanced')),
          canHaveValue: false
        }
      ];

    /**
     * store StepService context in self
     * JavaScript has block level context So it can be changed in nested
     * function
     */
    angular.extend(this, StepRepository);

    self.isDescriptionSaveInQueue = false;
    self.isDescriptionSaveInProgress = false;

    self.getStep = function () {
      return {
        id: null,
        checklist_id: null,
        title: '',
        summary: '',
        position: 1,
        assets: {
          data: []
        },
        allow_guest_owners: false,
        skip_start_process: false,
        owner: 'run_starter',
        deadline: {
          option: 'from',
          step: 'start_run',
          unit: 'days',
          value: 5
        },
        assignees: [],
        groups_invisible_to: [],
        settings: [],
        deadline_rules: [],
        alias: ''
      };
    };

    self.getDeadlineOptions = function () {
      return [{
        key: 'from',
        title: 'after',
        text: $filter('translate')('steps.deadlines.optionAfterBefore.after'),
        isDisable: false
      }, {
        key: 'prior_to',
        title: 'before',
        text: $filter('translate')('steps.deadlines.optionAfterBefore.before'),
        isDisable: false
      }];
    };

    self.getLogicStub = function () {
      return {
        terms: [],
        flag: '',
        isFirst: false
      };
    };

    self.getConditionCase = function () {
      return [
        'equals',
        'not_equals',
        'contains',
        'not_contains',
        'is_empty',
        'is_not_empty',
        'column_contains',
        'table_contains',
        'equals_any',
        'greater_than',
        'less_than',
      ];
    };

    self.getStepCase = function (stepType) {

      if (!stepType) {
        stepType = 'task';
      }

      var value = '';
      switch (stepType) {
        case 'task':
          value = ['completed'];
          break;
        case 'approval':
          value = ['approved', 'rejected'];
          break;
        case 'expiring':
          value = ['acknowledged', 'expired'];
          break;
        case 'expiring_email':
          value = ['acknowledged', 'expired'];
          break;
        default:
          value = ['completed'];
          break;
      }
      return value;
    };

    self.getStepsTips = function () {
      return [
        $filter('translate')('steps.tipsExample.clientExample.stepsForExample.stepOne'),
        $filter('translate')('steps.tipsExample.clientExample.stepsForExample.stepTwo'),
        $filter('translate')('steps.tipsExample.clientExample.stepsForExample.stepThree'),
        $filter('translate')('steps.tipsExample.clientExample.stepsForExample.stepFour'),
        $filter('translate')('steps.tipsExample.clientExample.stepsForExample.stepFive')
      ];
    };

    self.getStepValues = function () {
      return ['any_time'];
    };

    self.getLogicTermStub = function () {
      return {
        subject_id: "",
        subject_type: "",
        operation: "",
        statement: "",
        logic: "or"
      };
    };

    self.getStepDeadline = function (deadline) {
      if (deadline && deadline.value && deadline.unit) {
        return moment().add(parseInt(deadline.value), deadline.unit);
      }
      return moment().add(30, 'minutes');
    };

    self.createStep = function (data, params) {
      return StepRepository.save(data, params).$promise;
    };
    
    // @TODO To be uncommented when https://github.com/tallyfy/client/issues/15287 is fixed
    /*self.updateStep = function (data, params) {
      _.set(params, 'condition.terms', _.filter(_.get(params, 'condition.terms', []), function (t) {
        var isEmptyCheck = t.operation === 'is_not_empty' || t.operation === 'is_empty';
        return !isEmptyCheck && ((t.statement !== null) && (t.statement !== undefined));
      }));

      if (updateStepPromise) {
        updateStepPromise.$cancelRequest();
      }
      updateStepPromise = StepRepository.updateStep(_.omit(data, ['condition']), angular.extend(_.omit(params, ['condition']), {
        captures: _.filter(_.get(params, 'captures', []), function (f) { return _.get(f, 'label') !== null; })
      }));
      return updateStepPromise.$promise;
    };*/

    // @TODO To be deleted when https://github.com/tallyfy/client/issues/15287 is fixed
    self.updateStep = function (data, params) {
      _.set(params, 'condition.terms', _.filter(_.get(params, 'condition.terms', []), function (t) {
        var isEmptyCheck = t.operation === 'is_not_empty' || t.operation === 'is_empty';
        return !isEmptyCheck && ((t.statement !== null) && (t.statement !== undefined));
      }));
      
      if (updateStepPromise) {
        updateStepPromise.$cancelRequest();
      }
      if (params.assignees) {
        return Helper.discardDisabledUsersFromArr(_.get(params, 'assignees', [])).then(function (res) {
          params.assignees = res;
          updateStepPromise = StepRepository.updateStep(_.omit(data, ['condition']), angular.extend(_.omit(params, ['condition']), {
            captures: _.filter(_.get(params, 'captures', []), function (f) { return _.get(f, 'label') !== null; })
          }));
          return updateStepPromise.$promise;
        });
      } else {
        updateStepPromise = StepRepository.updateStep(_.omit(data, ['condition']), angular.extend(_.omit(params, ['condition']), {
          captures: _.filter(_.get(params, 'captures', []), function (f) { return _.get(f, 'label') !== null; })
        }));
        return updateStepPromise.$promise;
      }
    };

    // @TODO To be uncommented when https://github.com/tallyfy/client/issues/15287 is fixed
    /*self.updateSteps = function (data, params) {
      updateStepPromise = StepRepository.updateStep(data, params);
      return updateStepPromise.$promise;
    };*/

    // @TODO To be deleted when https://github.com/tallyfy/client/issues/15287 is fixed
    self.updateSteps = function (data, params) {
      if (params.assignees) {
        return Helper.discardDisabledUsersFromArr(_.get(params, 'assignees', [])).then(function (res) {
          params.assignees = res;
          updateStepPromise = StepRepository.updateStep(data, params);
          return updateStepPromise.$promise;
        });
      } else {
        updateStepPromise = StepRepository.updateStep(data, params);
        return updateStepPromise.$promise;
      }
    };

    self.updateStepField = function (args, data) {
      args = args || {};
      var params = {
        action: 'captures'
      };
      angular.extend(params, args);
      return StepRepository.update(params, data);
    };

    self.createStepCapture = function (args, data) {
      args = args || {};
      var params = {
        action: 'captures'
      };
      angular.extend(params, args);
      return StepRepository.save(params, data).$promise;
    };

    self.deleteStepCapture = function (args) {
      args = args || {};
      var params = {
        action: 'captures'
      };
      angular.extend(params, args);
      return StepRepository.delete(params);
    };

    self.deleteStep = function (params) {
      return StepRepository.delete(params);
    };

    self.clone = function (checklistId, stepId, stepTitle) {
      return StepRepository.create({ checklist_id: checklistId, id: stepId, action: 'clone' }, { title: stepTitle + ' - Copy' });
    };

    /**
     * @name validateFields
     * @description validates capture fields and return error
     * @param condition
     * @returns string
     */
    self.validateConditions = function (condition) {
      var flag = false;
      if (_.get(condition, 'terms.length') && !condition.isFirst) {
        _.each(condition.terms, function (condition) {
          if (_.isEmpty(condition.subject_id) || _.isEmpty(condition.subject_type) || _.isEmpty(condition.operation)) {
            flag = true;
            return;
          }

          if (condition.operation !== 'is_empty' && condition.operation !== 'is_not_empty' && _.isEmpty(condition.statement)) {
            flag = true;
            return;
          }
        });
        if (_.isEmpty(condition.flag)) {
          flag = true;
        }
      }
      return flag;
    };

    /**
     * @ngdoc method
     * @name prepareStep
     * @description Generate the step title from step and string like last_step, start_run etc.
     * @param {array} steps
     * @param {Object} deadline
     * @returns {String} step
     */
    function prepareStep(steps, deadline) {
      if (!_.isNaN(Number(deadline.step))) {
        var stepItem = _.find(steps, ['id', parseInt(deadline.step)]);
        if (!_.isUndefined(stepItem)) {
          return stepItem.title;
        }
        if (deadline.option === 'prior_to') {
          return 'Last step';
        }
        return 'Start of process';
      }
      return _.replace(deadline.step, '_', ' ');
    }

    /**
     * @ngdoc method
     * @name pluralizeDeadline
     * @description Make the deadline pluralize based on unit and value
     * @param {Integer} value
     * @param {String} unit
     * @returns {String} deadline
     */
    function pluralizeDeadline(value, unit) {
      if (value > 1) {
        return value + " " + unit;
      } else if (value === 1 && unit === 'day') {
        return value + " " + unit;
      }
      return value + " " + unit.slice(0, -1);
    }

    /**
     * @ngDoc method
     * @name checkUnsavedChanges
     * @description Check if a step is changed to show saving confirmation popup
     * @param step
     */
    self.checkUnsavedChanges = function (step) {
      var deffered = $q.defer();
      if (step.$dirty) {
        $confirm({
          'body': 'global.unsaved.body',
          'header': 'global.unsaved.header',
          'buttons': {
            'accept': 'global.unsaved.buttons.accept',
            'cancel': 'global.unsaved.buttons.cancel'
          },
          'modalType': 'modal-blueprint'
        }).then(function () {
          deffered.resolve('SAVE_CHANGES');
        }, function () {
          deffered.reject('DISCARD_CHANGES');
        });
      } else {
        deffered.reject('NO_CHANGES');
      }
      return deffered.promise;
    };

    /**
     * @function
     * @name validateCaptures
     * @param captures
     * @description
     * Check if capture has empty value
     * @returns {boolean}
     */
    function validateCaptures(captures) {
      var isInvalid = false;
      if (captures.length) {
        _.each(captures, function (capture) {
          if (_.isEmpty(capture.label)) {
            capture.isExpanded = true;
            isInvalid = true;
          }
          else if ((capture.field_type === 'table') && capture.label && _.isEmpty(_.get(capture, 'columns[0].label', ''))) {
            capture.isExpanded = true;
            isInvalid = true;
          }
          else if ((capture.field_type === 'dropdown' || capture.field_type === 'multiselect' || capture.field_type === 'radio') && _.isEmpty(_.get(capture, 'options[0].text', ''))) {
            capture.isExpanded = true;
            isInvalid = true;
          }
          else if ((capture.field_type === 'dropdown' || capture.field_type === 'multiselect' || capture.field_type === 'radio') && (((_.get(capture, 'options', []).length > 1) || capture.isNew) && _.isEmpty(_.get(capture, 'options[1].text', '')))) {
            capture.isExpanded = true;
            isInvalid = true;
          }
        });
      }
      return isInvalid;
    }

    /**
     * @function
     * @name validateStepFields
     * @param step
     * @description
     * Check if step fields has an empty value
     * @returns {boolean}
     */
    self.validateStepFields = function (step) {
      // Restrict user to move to another tab/step when required fields are empty in deadline tab
      var deadlineValue = _.get(step, 'deadline.value');
      if (step.deadline && (deadlineValue === undefined || deadlineValue === null)) {
        return true;
      }

      // Restrict user to move to another tab/step when required fields are empty in advanced tab
      /* if (step.isWebhookRequired) {
        $rootScope.$broadcast('STEP:ADVANCED_TAB_TO_STEP');
        return true;
      } */

      // Restrict user to move to another tab/step when required fields are empty in logic tab
      var terms = _.get(step.condition, 'terms');
      if (_.isArray(terms) && self.validateConditions(step.condition)) {
        $rootScope.$broadcast('STEP:CHANGED_SELECTED_CONDITION_TAB');
        return true;
      }

      // Restrict user to move to another tab/step when required fields are empty in forms tab
      var captures = _.get(step, 'captures');
      if (_.isArray(captures) && validateCaptures(step.captures)) {
        $rootScope.$broadcast('STEP:CHANGED_SELECTED_FORM_TAB');
        return true;
      }

      return false;
    };

    self.validatePrerunFields = function (process) {
      var preruns = _.get(process, 'prerun', []);
      return validateCaptures(preruns);
    };

    /**
     * @function
     * @name validateRule
     * @param process
     * @param step
     * @description Find step, capture and prerun are used in rule
     * @returns {array} rule
     */
    self.validateRule = function (process, step) {
      rule = [];
      var rules = [];
      _.forEach(process.automated_actions, function (automated_action) {
        if (!Helper.isObjectEmpty(automated_action.conditions)) {
          _.find(automated_action.conditions, function (condition) {
            if (condition.conditionable_type === 'Capture') {
              rules = self.validateStepCapture(automated_action, step, condition, 'condition');
            }
            if (condition.conditionable_type === 'Step' || condition.conditionable_type === 'Prerun') {
              rules = self.validateStep(automated_action, step, condition, 'condition');
            }
          });
        }
        if (!Helper.isObjectEmpty(automated_action.then_actions)) {
          _.find(automated_action.then_actions, function (action) {
            if (action.actionable_type === 'Capture') {
              rules = self.validateStepCapture(automated_action, step, action, 'action');
            }
            if (!action.actionable_type || action.actionable_type === 'Step' || action.actionable_type === 'Prerun') {
              rules = self.validateStep(automated_action, step, action, 'action');
            }
          });
        }
      });
      return rules;
    };

    /**
     * @function
     * @name validateStepCapture
     * @param entity
     * @param field
     * @param title
     * @param position
     * @description validate step captures
     * @returns {array} rule
     */
    self.validateStepCapture = function (automatedAction, step, entity, type) {
      if (step.captures) {
        _.find(step.captures, function (val) {
          rule = self.validateStep(automatedAction, val, entity, type);
        });
      } else {
        rule = self.validateStep(automatedAction, step, entity, type);
      }
      return rule;
    };

    /**
     * @function
     * @name validateStep
     * @param entity
     * @param field
     * @param title
     * @param position
     * @description check if selected field match with step/prerun capture
     * @returns {array} rule
     */
    self.validateStep = function (automatedAction, field, entity, type) {
      if (field.id === entity.conditionable_id || field.id === entity.target_step_id) {
        rule.push({
          id: automatedAction.id,
          label: $filter('translate')('steps.automationValidation.' + type, { automationName: automatedAction.automated_alias, position: entity.position })
        });
      }
      return rule;
    };

    /**
     * @function
     * @name openRuleModal
     * @param rule
     * @param subjectType
     * @description List of step name open in the modal
     */
    self.openRuleModal = function (rule, subjectType, process) {
      $uibModal.open({
        component: 'validateStepRule',
        windowClass: 'modal-rule',
        backdrop: "static",
        resolve: {
          rule: function () {
            return _.uniq(rule);
          },
          subjectType: function () {
            return subjectType;
          },
          process: function () {
            return process ? process : null;
          }
        }
      });
    };

    /**
     * @function
     * @name openKickFieldInValidModal
     * @param rule
     * @param subjectType
     * @description Open edited invalid messages in the modal
     */
    self.openKickFieldInValidModal = function (subjectType) {
      $uibModal.open({
        component: 'kickFormInValid',
        windowClass: 'modal',
        backdrop: "static",
        resolve: {
          subjectType: function () {
            return subjectType;
          }
        }
      });
    };

    /**
     * @function
     * @name isSetCondition
     * @param step
     * @description Check step condition is valid
     * @return Boolean
     */
    self.isValidStepCondition = function (step) {
      if (!_.get(step, 'condition') || _.isEmpty(step.condition) || _.isUndefined(step.condition.terms) || !step.condition.terms.length) {
        step.condition = [];
        return false;
      }
      var isValid = true;
      if (_.get(step, 'condition.terms.length') && !step.condition.isFirst) {
        _.forEach(step.condition.terms, function (term) {
          var isEmptyCheck = term.operation === 'is_not_empty' || term.operation === 'is_empty', isTermValid = term.subject_id && term.operation && isEmptyCheck ? true : term.statement;
          if (!isTermValid) {
            isValid = false;
            return false;
          }
        });
      }

      return isValid;
    };

    /**
     * @name validateDeadlineRule
     * @param steps
     * @param field
     * @description Find deadline are used in step
     * @returns {array} rule
     */
    self.validateDeadlineRule = function (steps, field) {
      rule = [];
      _.forEach(steps, function (step) {
        if (_.toLower(step.deadline.step) !== 'start_run' && field.id === step.deadline.step) {
          rule.push({
            alias: step.alias,
            label: $filter('translate')('steps.validateStepDeadline.deadline', { title: step.title, position: step.position })
          });
        }
      });
      return rule;
    };

    /*
     * @function
     * @name getModalContent
     * @param subjectType
     * @description return modal content matched to the subject type
     * @returns {Object}
     */
    self.getModalContent = function (subjectType) {
      var data = _.find(modalContent, { key: _.lowerCase(subjectType) });
      return data.content;
    };

    self.getAllDeadlines = function (params) {
      return StepRepository.getStepDeadlines(params).$promise;
    };

    /**
     * @name getStepOptions
     * @returns array of step options
     *
     * @description
     * get step options value
     */
    self.getStepOptions = function () {
      return [
        { name: $filter('translate')('steps.title.assign'), value: 'assign', class: '', eventText: 'Assign', isEnable: true, tooltip: $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.assign')) },
        { name: $filter('translate')('steps.title.timings'), value: 'set-deadline', class: '', eventText: 'Timings', isEnable: true, tooltip: $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.timings')) },
        { name: $filter('translate')('steps.title.describe'), value: 'describe_capture', class: 'active', eventText: 'Description', isEnable: true, tooltip: $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.description')) }
      ];
    };

    self.getStepAdvancedOptions = function () {
      var isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
      return [
        { name: $filter('translate')('steps.title.outbound'), value: 'outbound', class: '', eventText: 'Outbound', isEnable: !isDocsPlan, tooltip: $sce.trustAsHtml($filter('translate')('steps.settings.advanced.outbound')) },
        { name: $filter('translate')('steps.title.inbound'), value: 'inbound', class: '', eventText: 'Inbound', isEnable: !isDocsPlan, tooltip: $sce.trustAsHtml($filter('translate')('steps.settings.advanced.inbound', { apiURL: CONFIG.API_HOST })) }
      ]
    };

    /**
     * @ngdoc method
     * @name getStepDeadlineAsStr
     * @param {*} steps
     * @param {*} currentStep
     * @returns {String} deadline
     */
    self.getStepDeadlineAsStr = function (steps, currentStep) {
      var step = prepareStep(steps, currentStep.deadline),
        selectedStep;

      if (step === 'start run') {
        step = $filter('translate')('steps.deadlines.start_process');
      }
      selectedStep = _.find(steps, function (st) {
        return st.id === step;
      });
      if (!_.isUndefined(selectedStep)) {
        step = $filter('translate')('steps.logic.label.stepTitle', {
          position: selectedStep.position,
          title: selectedStep.title
        });
      }
      var deadline = pluralizeDeadline(currentStep.deadline.value, currentStep.deadline.unit),
        option = $filter('translate')('steps.deadlines.option.' + currentStep.deadline.option);
      return deadline + ' ' + option + ' ' + step;
    };

    /**
     * @ngdoc method
     * @name getStepStartAsStr
     * @param {*} steps
     * @param {*} currentStep
     * @returns {String} deadline
     */
    self.getStepStartAsStr = function (steps, currentStep) {
      var date = currentStep.start_date || currentStep.deadline;
      var startDate = pluralizeDeadline(date.value, date.unit),
        step = $filter('translate')('steps.timing.start_description');
      return startDate + ' ' + step;
    };

    /**
     * @ngdoc method
     * @name stepOwnersAsStr
     * @param {*} step
     * @returns {String} owners
     */
    self.stepOwnersAsStr = function (step) {
      var stepsOwners = _.union(step.selectedStepOwners || [], step.selectedGroups || [], step.selectedGuests || []);
      stepsOwners = _.sortBy(stepsOwners, 'text');
      if (step.allow_guest_owners) {
        stepsOwners.push(stepGuestOwner);
      }
      return stepsOwners;
    };

    /**
     * @ngdoc method
     * @name showInvalidStepFields
     * @param {*} selectedStep
     * @param tabName
     */
    self.showInvalidStepFields = function (selectedStep, tabName) {
      if (_.has(selectedStep, 'title') && _.isEmpty(selectedStep.title)) {
        DOMService.centerObjectToView(DOM.ERROR_VIEW.ERROR_ELEMENT);
        return true;
      }
      if (self.validateStepFields(selectedStep)) {
        growl.error($filter('translate')('process.messages.invalidFields', {
          stepNumber: selectedStep.position,
          tabName: tabName.eventText,
          stepName: _.truncate(selectedStep.title, {
            'length': 24
          })
        }), {
          referenceId: 'global',
          disableIcons: true,
          disableCloseButton: true
        });
        DOMService.centerObjectToView(DOM.ERROR_VIEW.ERROR_ELEMENT);
        return true;
      }
      return false;
    };

    /**
     * @ngdoc method
     * @name updateOwnerByType
     * @public
     *
     * @description update owners to step by assignee type
     * run_starter - Assigne current user to step
     * specific - Parse users object, convert to array and transfrom user's Ids to integer
     * everyone - Assigne all users who are available in ORG
     *
     * @param {object} step  A step to be updated
     * @returns void
     */
    self.updateOwnerByType = function (step) {
      if (!_.has(step, 'assignees') || !_.get(step, 'assignees', []).length) {
        step.assignees = [];
      }
    };

    /**
    * @ngdoc method
    * @name prepareStepAssignees
    * @description perpare the step assignees obejct
    * @param {Object} step
    * @param {Object} availableUsers
    * @param {Object} orgGroups
    * @returns {Object} step
    */
    self.prepareStepAssignees = function (step, availableUsers, orgGroups) {
      step.selectedStepOwners = [];
      step.selectedGuests = [];
      step.selectedGroups = [];
      step.owner = _.get(step, 'owner', 'none');

      _.forEach(step.assignees || [], function (ownerVal) {
        _.forEach(availableUsers, function (userVal) {
          if (ownerVal === userVal.id) {
            step.selectedStepOwners.push(angular.copy(userVal));
          }
        });
      });

      _.forEach(step.guests || [], function (guestItem) {
        step.selectedGuests.push({ id: guestItem, text: guestItem });
      });

      _.forEach(step.groups || [], function (groupItem) {
        var groupBaseObj = _.find(orgGroups, { id: groupItem.id || groupItem });
        angular.extend(groupBaseObj, groupItem);
        groupBaseObj.text = groupBaseObj.name;
        step.selectedGroups.push(groupBaseObj);
      });
      return step;
    };

    // is any dirty step expand
    self.isAnyDirtyStepExpanded = function (selectedStep) {
      return !(selectedStep.$dirty && selectedStep.isExpanded);
    };

    // check unsaved desc
    self.isDescHasUnsavedChanges = function (tempBPDesc, process) {
      return tempBPDesc !== process.summary;
    };

    // open tips modal
    self.openStepTipsModal = function () {
      $uibModal.open({
        component: 'stepTipsModal',
        windowClass: 'step-tips-modal',
        resolve: {
        }
      }).result.then(function () {
      }, function () {
        $log.info('Modal is cancelled');
      });
    };

    self.openPowerBuilder = function (process, steps, type, users, orgGroups) {
      if (!Helper.checkAccessAuthority())
        return;
      return $uibModal.open({
        component: 'powerTools',
        windowClass: 'modal-super',
        resolve: {
          process: function () {
            return {
              id: process.id,
              title: process.title,
              stepsLength: steps.length,
              steps: steps,
              type: type,
              startedAt: _.get(process, 'started_at'),
              data: process
            };
          },
          users: function () {
            return users;
          },
          orgGroups: function () {
            return orgGroups;
          }
        }
      });
    };

    // modal confirm for dirty check
    self.openConfirmDirtyCheckModal = function () {
      return $confirm({
        'body': 'global.unsaved.body',
        'header': 'global.unsaved.header',
        'buttons': {
          'accept': 'global.unsaved.buttons.accept',
          'cancel': 'global.unsaved.buttons.cancel'
        },
        'modalType': 'modal-blueprint'
      });
    };

    // check if step capture is invalid
    self.isStepCapturesInvalid = function (step) {
      var isInvalid = false, captures = _.get(step, 'captures', []);

      for (var i = 0; i < captures.length; i++) {
        if (_.isEmpty(captures[i].label) && !captures[i].isNew) {
          captures[i].isExpanded = true;
          isInvalid = true;
          break;
        }

        if ((captures[i].field_type === 'table') && captures[i].label && _.isEmpty(_.get(captures[i], 'columns[0].label', ''))) {
          captures[i].isExpanded = true;
          isInvalid = true;
        }

        if (captures[i].field_type === 'dropdown' || captures[i].field_type === 'multiselect' || captures[i].field_type === 'radio') {
          if (_.isEmpty(_.get(captures[i], 'options[0].text', ''))) {
            captures[i].isExpanded = true;
            isInvalid = true;
            break;
          }

          if (((_.get(captures[i], 'options', []).length > 1) || captures[i].isNew) && _.isEmpty(_.get(captures[i], 'options[1].text', ''))) {
            captures[i].isExpanded = true;
            isInvalid = true;
            break;
          }
        }
      }
      return isInvalid;
    };

    /** discard method ko form step */
    self.discardStepFormFields = function (stepToDiscard, oldValue) {
      for (var i = 0; i < (oldValue.captures || []).length; i++) {
        angular.extend(stepToDiscard.captures[i], oldValue.captures[i]);
        stepToDiscard.captures[i] = _.omit(stepToDiscard.captures[i], ['isExpanded', 'isFFSave']);
      }
      stepToDiscard.captures = _.filter(stepToDiscard.captures, function (value) {
        return !value.isNew;
      });
    };

    // open delete modal confirmation
    self.openDeleteStepConfirmation = function (step) {
      return $confirm({
        'header': $filter('translate')('global.confirmAction.header', {
          actionName: $filter('translate')('global.confirmAction.actionDelete'),
          componentName: 'Step'
        }),
        'body': $filter('translate')('global.confirmAction.stepDelete', {
          actionName: _.toLower($filter('translate')('global.confirmAction.actionDelete')),
          componentName: step.title
        }),
        'buttons': {
          'accept': $filter('translate')('global.confirmAction.buttons.accept', {
            actionName: $filter('translate')('global.confirmAction.actionDelete')
          }),
          'cancel': 'global.confirmAction.buttons.cancel'
        },
        modalType: 'modal-danger'
      });
    };

    self.getStepTypes = function () {
      return [
        {
          label: $filter('translate')('steps.logic.label.standardTaskText'), value: 'task', shortInfo: $filter('translate')('steps.logic.shortNote.standardTaskText'),
          iconClasses: { type: "fas fa-check w-16 circle text-10px d-flex align-items-center justify-content-center", greyscale: "bg-steel-three", colored: "nasty-green" },
          onlyText: $filter('translate')('steps.logic.label.standardTaskText'),
          text: "<i class='fas fa-check w-16 circle nasty-green text-10px mr-2 d-flex align-items-center justify-content-center'></i><span>" + $filter('translate')('steps.logic.label.standardTaskText') + "</span>"
        },
        {
          label: $filter('translate')('steps.logic.label.approveText'), value: 'approval', shortInfo: $filter('translate')('steps.logic.shortNote.approveText'),
          iconClasses: { type: "tlfy-apr-rej-icon w-16", greyscale: "greyscale", colored: "" },
          onlyText: $filter('translate')('steps.logic.label.approveText') + ' / ' + $filter('translate')('steps.logic.label.rejectText'),
          text: "<i class='tlfy-apr-rej-icon w-16 mr-2'></i><span class='mr-1'>" + $filter('translate')('steps.logic.label.approveText') + "</span>/<span class='ml-1'>" + $filter('translate')('steps.logic.label.rejectText') + "</span>"
        },
        {
          label: $filter('translate')('steps.logic.label.expiringText'), value: 'expiring', shortInfo: $filter('translate')('steps.logic.shortNote.expiringText'),
          iconClasses: { type: "fas fa-clock w-16 circle d-flex text align-items-center justify-content-center", greyscale: "t-steel-three", colored: "t-brown-500" },
          onlyText: $filter('translate')('steps.logic.label.expiringText'),
          text: "<i class='fas fa-clock w-16 circle t-steel-three mr-2 d-flex text align-items-center justify-content-center'></i><span>" + $filter('translate')('steps.logic.label.expiringText') + "</span>"
        },
        {
          label: $filter('translate')('steps.logic.label.email'), value: 'email', shortInfo: $filter('translate')('steps.logic.shortNote.email'),
          iconClasses: { type: "fas fa-envelope w-16 circle d-flex text align-items-center justify-content-center", greyscale: "t-steel-three", colored: "t-steel-three" },
          onlyText: $filter('translate')('steps.logic.label.email'),
          text: "<i class='fas fa-envelope w-16 circle t-steel-three mr-2 d-flex text align-items-center justify-content-center'></i><span>" + $filter('translate')('steps.logic.label.email') + "</span>"
        },
        {
          label: $filter('translate')('steps.logic.label.emailAutoSend'), value: 'expiring_email', shortInfo: $filter('translate')('steps.logic.shortNote.emailAutoSendText'),
          iconClasses: { type: "fas fa-paper-plane w-16 circle d-flex text align-items-center justify-content-center", greyscale: "t-steel-three", colored: "t-steel-three" },
          onlyText: $filter('translate')('steps.logic.label.emailAutoSend'),
          text: "<i class='fas fa-paper-plane w-16 circle t-steel-three mr-2 d-flex text align-items-center justify-content-center'></i><span>" + $filter('translate')('steps.logic.label.emailAutoSend') + "</span>"
        }
      ];
    };

    self.openRightPaneOnFirstStepCreate = function (process, step) {
      var stepTabs = self.getStepOptions(process), activeTab = _.head(stepTabs);
      $rootScope.$emit('RIGHT_PANE:OPEN', {
        item: {
          index: 0,
          step: step,
          activeTab: activeTab
        }
      });
    };

    self.getAssignTypes = function () {
      return [
        { value: 'assign', text: 'global.task.label.assign', iconClass: 'fa-user-plus' },
        { value: 'unassign', text: 'global.task.label.unassign', iconClass: 'fa-user-minus' }
      ];
    };

    self.getDeadlineTypes = function () {
      return [
        { value: 'processLaunch', text: 'global.task.label.processLaunch' },
        { value: 'specificDate', text: 'global.task.label.specificDate' },
        { value: 'today', text: 'global.task.label.today' }
      ];
    };

    self.getProgressStatus = function () {
      return [
        { value: 'started', text: $filter('translate')('global.blueprint.progressBar.statusDropdown.started'), percentage: 25, statusClass: 'started' },
        { value: 'in_progress', text: $filter('translate')('global.blueprint.progressBar.statusDropdown.in_progress'), percentage: 50, statusClass: 'in-progress' },
        { value: 'almost_completed', text: $filter('translate')('global.blueprint.progressBar.statusDropdown.finishing'), percentage: 75, statusClass: 'finishing' },
        { value: 'completed', text: $filter('translate')('global.blueprint.progressBar.statusDropdown.completed'), percentage: 100, statusClass: 'completed' }
      ];
    };

    self.updateBlueprintStatus = function (arg) {
      var deferred = $q.defer();
      self.setBlueprintStatus(arg).then(function (response) {
        deferred.resolve(response);
        $timeout(function () {
          growl.success($filter('translate')('steps.messages.statusUpdate'), {
            referenceId: 'global',
            disableIcons: true,
            disableCloseButton: true
          });
        }, 350);
      });
      return deferred.promise;
    };

    self.setBlueprintStatus = function (arg) {
      return StepRepository.setBlueprintStatus({
        checklist_id: arg.checklist_id
      }, arg).$promise;
    };

    self.getAllRoles = function () {
      var defer = $q.defer();
      StepRepository.getAllRoles().$promise.then(function (response) {
        response.data = response.data || [];
        _.forEach(response.data, function (item, i) {
          item.escaped_title = item.title.replaceAll("'", "\\'");
        });
        defer.resolve(response);
      }, function (error) {
        defer.reject(error);
      });
      return defer.promise;
    };

    self.createRoles = function (data) {
      return StepRepository.createRoles(data).$promise;
    };

    self.linkRoleToStep = function (data) {
      var args = {
        org_role_id: data.id,
        subject_type: "Step",
        subject_id: data.step_id
      };
      return StepRepository.linkRoleToStep(args).$promise;
    };

    self.unlinkRoleToStep = function (args) {
      return StepRepository.unlinkRoleToStep(args).$promise;
    };

    self.editRole = function (roleId, data) {
      return StepRepository.editRole({ roleId: roleId }, data).$promise;
    };

    self.deleteRole = function (roleId) {
      return StepRepository.deleteRole({ roleId: roleId }).$promise;
    };

    self.getStepCategories = function () {
      return stepCategories;
    };

    self.moveFieldOneStepToAnotherStep = function (arg, data) {
      return StepRepository.moveFieldOneStepToAnotherStep(arg, data).$promise;
    };
  }
})();
