/**
 * @ngdoc Component
 * @name tallyfy.steps.component.blueprintCreateModal
 * @module tallyfy.steps
 *
 * @description
 * A component to open blue print modal
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.process')
    .component('blueprintCreateModal', {
      templateUrl: 'app/modules/process/components/blueprint-create/blueprint-create-modal.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $q, MilestoneService, $scope, $rootScope, blockUI, $state, $stateParams, Upload, Growl, ENV_CONFIG, ProcessService, CONST, MODAL_CLOSE_EVENTS, $analytics, Helper, TagsService, $sce, $timeout, StepService, FolderService, PLANS, openAIService, OrganizationsService, DESCRIPTIONSIZE) {
          var $ctrl = this,
            growl = new Growl(),
            reader = new FileReader(),
            blockUI = blockUI.instances.get('blueprint'), newProcess,
            onModalClosePreventHandler,
            folderSelectedEventHandler;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.cancelModal = cancelModal;
          $ctrl.saveProcess = saveProcess;
          $ctrl.setTabActive = setTabActive;
          $ctrl.stepTypes = StepService.getStepTypes();
          $ctrl.selectedStepType = null;
          $ctrl.stepEditTypeOpen = {};
          $ctrl.popoverIsOpen = {};

          $ctrl.nextView = nextView;
          $ctrl.onClickHelp = onClickHelp;
          $ctrl.backView = backView;
          $ctrl.checkValid = checkValid;
          $ctrl.selectBlueprintType = selectBlueprintType;
          $ctrl.generateTextCompletion = generateTextCompletion;
          $ctrl.removeAttribute = removeAttribute;
          $ctrl.deleteSuggestedStep = deleteSuggestedStep;
          $ctrl.deleteSuggestedStepDoc = deleteSuggestedStepDoc;
          $ctrl.deleteSuggestedMilestone = deleteSuggestedMilestone;
          $ctrl.deleteSuggestedMilestoneDoc = deleteSuggestedMilestoneDoc;
          $ctrl.getStepType = getStepType;
          $ctrl.stepTypeDDToggle = stepTypeDDToggle;
          $ctrl.onStepTypeSelected = onStepTypeSelected;
          $ctrl.addStep = addStep;
          $ctrl.saveStepInStage = saveStepInStage;
          $ctrl.cancelAddNewStepInStage = cancelAddNewStepInStage;
          $ctrl.getStepsLength = getStepsLength;
          $ctrl.uploadFile = uploadFile;
          $ctrl.openFileUpload = openFileUpload;
          $ctrl.newStepInStage = {};
          $ctrl.unmappedSteps = [];
          $ctrl.allowedImportFile = CONST.DOC_AI_SOP_ALLOWED_IMPORT.join(',');
          $ctrl.generateProcedureFromDocument = generateProcedureFromDocument;

          /**
           * public properties
           */
          $ctrl.activeView = 'type_blueprint_name';
          $ctrl.blueprintType = 'procedure';
          $ctrl.industries = [];
          $ctrl.isIndustryDropdownOpen = false;
          $ctrl.maxLength = DESCRIPTIONSIZE.default;

          $ctrl.availableBlueprintTypes = [
            { label: 'Document', value: 'document' },
            { label: 'Procedure', value: 'procedure' }
          ];

          $ctrl.availableLanguages = [
            { label: 'English', value: 'english' },
            { label: 'Spanish', value: 'spanish' },
            { label: 'German', value: 'german' },
            { label: 'Norwegian', value: 'norwegian' },
            { label: 'Swedish', value: 'swedish' },
            { label: 'Portuguese', value: 'portuguese' },
            { label: 'Hindi', value: 'hindi' },
            { label: 'Indonesian', value: 'indonesian' },
            { label: 'Dutch', value: 'dutch' },
            { label: 'Finnish', value: 'finnish' },
            { label: 'Japanese', value: 'japanese' },
            { label: 'Italian', value: 'italian' },
            { label: 'Danish', value: 'danish' }
          ];

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.selectedBlueprintType = $ctrl.availableBlueprintTypes[1];
            $ctrl.isFreePlan = _.get($rootScope.identity, 'default_organization.subscription.data.plan_code') === PLANS.FREE;
            $ctrl.percentage = 50;
            $ctrl.activeTab = $stateParams.activeTab || 'fromScratch';
            selectBlueprintType();
            if ($ctrl.resolve.folderId) {
              FolderService.getFolderById({ id: $ctrl.resolve.folderId, skipNotFound: true }).then(function (response) {
                $ctrl.selectFolder = response.data;
              });
            }
            $ctrl.selectedLanguage = $ctrl.availableLanguages[0];
            getOrganizationRoles();
            getIndustries();
            $ctrl.organization = _.get($rootScope.identity, 'default_organization');
            if (!_.isNull($ctrl.organization.industry)) {
              $ctrl.companyTypeGenerated = { "name": $ctrl.organization.industry };
            }
            reader.onload = function () {
              $ctrl.documentText = reader.result.split(',')[1];
            };
            reader.onerror = function (error) {
              Growl.clearAllMessages('global');
              growl.error($filter('translate')('ai.encodeError'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            };
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            onModalClosePreventHandler();
            folderSelectedEventHandler();
            blockUI.stop();
          }

          function getOrganizationRoles() {
            StepService.getAllRoles()
              .then(function (res) {
                $ctrl.orgRoles = res.data;
              });
          }

          function setTabActive(active) {
            if (active === 'fromExample') {
              $('.modal-open').addClass('from-examples-part');
            } else {
              $('.modal-open').removeClass('from-examples-part');
            }
            $ctrl.activeTab = active;
            $state.transitionTo($state.current, angular.extend($stateParams, { activeTab: $ctrl.activeTab }), { notify: false });
          }

          function checkValid() {
            return !!($ctrl.activeView === 'type_blueprint_name' && _.get($ctrl.process, 'title', '') === '');
          }

          function nextView() {
            switch ($ctrl.activeView) {
              case 'type_blueprint_name':
                $ctrl.activeView = "select_blueprint_type";
                break;
              default:
                $ctrl.activeView = "type_blueprint_name";
            }
            var add = 50;
            $ctrl.percentage = parseInt($ctrl.percentage) + add;
          }

          function onClickHelp() {
            window.open('https://tallyfy.com/products/pro/documenting/templates/', '_blank');
          }

          function backView() {
            switch ($ctrl.activeView) {
              case 'select_blueprint_type':
                $ctrl.activeView = "type_blueprint_name";
                break;
              default:
                $ctrl.activeView = "type_blueprint_name";
            }
            var sub = 50;
            $ctrl.percentage = parseInt($ctrl.percentage) - sub;
          }

          /**
           * @function
           * @name saveProcess
           * @description create process
           */
          function saveProcess() {
            if ($ctrl.onSaving) {
              return;
            }
            if ($ctrl.activeTab === 'fromScratch') {
              $ctrl.suggestedStages = [];
              $ctrl.suggestedStagesDoc = [];
            } else if ($ctrl.activeTab === 'fromTallyfy') {
              $ctrl.suggestedStagesDoc = [];
            } else {
              $ctrl.blueprintType = 'procedure';
              $ctrl.suggestedStages = [];
            }
            if (($ctrl.suggestedStages && $ctrl.suggestedStages.length) || ($ctrl.suggestedStagesDoc && $ctrl.suggestedStagesDoc.length)) {
              $ctrl.generatingSuggestedStep = true;
            }
            if ($ctrl.processForm && $ctrl.processForm.$valid && !blockUI.state().blocking) {
              $ctrl.onSaving = true;
              ProcessService.createProcess({
                allow_steps_editing: 'yes',
                folder_id: _.get($ctrl.selectFolder, 'id', null),
                type: $ctrl.activeTab === 'fromTallyfy' ? $ctrl.selectedBlueprintType.value : $ctrl.blueprintType,
                title: ProcessService.capitalizeFirstLetterOfEachWord($ctrl.processTitle)
              }).then(function (response) {
                $ctrl.onSaving = false;
                newProcess = response.data;
                if ($ctrl.tags && $ctrl.tags.length > 0) {
                  var lastTag = _.last($ctrl.tags);
                  _.forEach($ctrl.tags, function (tag) {
                    TagsService.attachTag(tag, {
                      id: _.get(response.data, 'id'),
                      type: "Checklist"
                    }).then(function () {
                      if (lastTag.id === tag.id) {
                        trackEvent({ label: 'BLUEPRINT CREATED', blueprintId: newProcess.id, processName: newProcess.title, type: $ctrl.blueprintType });
                        $ctrl.close({ $value: newProcess });
                      }
                    });
                  });
                } else if ($ctrl.activeTab === 'fromTallyfy') {
                  if ($ctrl.selectedBlueprintType.value === 'document' && $ctrl.documentSummary) {
                    saveDocumentDescription(newProcess)
                  } else if ($ctrl.selectedBlueprintType.value === 'procedure') {
                    if ($ctrl.suggestedStages && $ctrl.suggestedStages.length) {
                      generateOpenAiSuggestion($ctrl.suggestedStages, $ctrl.unmappedSteps, newProcess);
                    } else if ($ctrl.unmappedSteps.length) {
                      $ctrl.generatingSuggestedStep = true;
                      var createStepsRequest = createSteps(null, $ctrl.unmappedSteps);
                      if (createStepsRequest) {
                        createStepsRequest.then(function () {
                          $ctrl.generatingSuggestedStep = false;
                          trackEvent({ label: 'BLUEPRINT CREATED', blueprintId: newProcess.id, processName: newProcess.title, type: $ctrl.blueprintType });
                          $ctrl.close({ $value: newProcess });
                        });
                      }
                    }
                  } else {
                    $ctrl.close({ $value: newProcess });
                  }
                } else if ($ctrl.activeTab === 'existingDocument') {
                  if ($ctrl.suggestedStagesDoc && $ctrl.suggestedStagesDoc.length) {
                    generateOpenAiSuggestion($ctrl.suggestedStagesDoc, $ctrl.unmappedStepsDoc, newProcess);
                  } else if ($ctrl.unmappedStepsDoc.length) {
                    $ctrl.generatingSuggestedStep = true;
                    var createStepsRequest = createSteps(null, $ctrl.unmappedStepsDoc);
                    if (createStepsRequest) {
                      createStepsRequest.then(function () {
                        saveFile(newProcess);
                      });
                    }
                  }
                } else {
                  $ctrl.close({ $value: newProcess });
                }
              }, function () {
                blockUI.stop();
                $ctrl.onSaving = false;
              });
            }
          }

          function saveDocumentDescription() {
            var payload = {
              title: newProcess.title,
              summary: $ctrl.documentSummary
            };
            ProcessService.update({
              id: newProcess.id
            }, payload).then(function (res) {
              $ctrl.close({ $value: res.data });
            })
          }

          function createStage(data, index) {
            var defer = $q.defer();
            MilestoneService.createTemplateMilestone(newProcess.id, data[index].title, 'Checklist', data[index].position + 1)
              .then(function (res) {
                var createStepsRequest = null;
                if (data[index].steps.length) {
                  createStepsRequest = createSteps(res.data, data[index].steps);
                }
                if (createStepsRequest) {
                  createStepsRequest.then(function () {
                    if (index === (Object.keys(data).length - 1)) {
                      defer.resolve(null);
                    } else {
                      var createStageRequest = createStage(data, index + 1);
                      createStageRequest.then(function (res) {
                        defer.resolve();
                      });
                    }
                  });
                }
              }, function () {
                $ctrl.generatingSuggestedStep = false;
              });
            return defer.promise;
          }

          function createStages(data, index) {
            var defer = $q.defer();
            if (!index) {
              index = 0;
            }
            var createStageRequest = createStage(data, index);
            createStageRequest
              .then(function (res) {
                defer.resolve();
              }, function () {
                $ctrl.generatingSuggestedStep = false;
              });
            return defer.promise;
          }

          function createStep(stage, data, index) {
            var defer = $q.defer(), defaultDeadline = _.get($rootScope.identity, 'default_organization.default_deadline');
            var step = StepService.getStep();
            step.deadline['value'] = defaultDeadline ? defaultDeadline.value : 5;
            step.deadline['unit'] = defaultDeadline ? defaultDeadline.type : 'days';
            step.summary = data[index].step_description;
            step.title = data[index].step_name;
            step.step_type = data[index].task_type;
            step.position = index;
            angular.extend(step, { checklist_id: newProcess.id });
            StepService.createStep({ checklist_id: newProcess.id }, step)
              .then(function (res) {
                if (stage) {
                  MilestoneService.linkStepToMilestone(newProcess.id, res.data.id, stage.id)
                    .then(function (res) {
                      if (index === (Object.keys(data).length - 1)) {
                        defer.resolve();
                      } else {
                        var createStepRequest = createStep(stage, data, index + 1);
                        createStepRequest.then(function (res) {
                          defer.resolve();
                        });
                      }
                      trackEvent({ label: 'STEP CREATED IN BLUEPRINT', blueprintId: newProcess.id, stepId: res.id, stepName: res.title });
                    });
                } else {
                  if (index === (Object.keys(data).length - 1)) {
                    defer.resolve();
                  } else {
                    var createStepRequest = createStep(stage, data, index + 1);
                    createStepRequest.then(function (res) {
                      defer.resolve();
                    });
                  }
                  trackEvent({ label: 'STEP CREATED IN BLUEPRINT', blueprintId: newProcess.id, stepId: res.id, stepName: res.title });
                }
              });
            return defer.promise;
          }

          function createSteps(stage, data, index) {
            var defer = $q.defer();
            if (!index) {
              index = 0;
            }
            var createStepRequest = createStep(stage, data, index);
            createStepRequest
              .then(function (res) {
                defer.resolve();
              }, function () {
                $ctrl.generatingSuggestedStep = false;
              });
            return defer.promise;
          }

          function generateOpenAiSuggestion(steps, unmappedSteps, process) {
            $ctrl.generatingSuggestedStep = true;
            var createStagesRequest = createStages(steps);
            createStagesRequest.then(function (res) {
              if (unmappedSteps.length) {
                var createStepsRequest = createSteps(null, unmappedSteps);
                if (createStepsRequest) {
                  createStepsRequest.then(function () {
                    if ($ctrl.activeTab === 'existingDocument') {
                      saveFile(process);
                    } else {
                      $ctrl.generatingSuggestedStep = false;
                      trackEvent({ label: 'BLUEPRINT CREATED', blueprintId: process.id, processName: process.title, type: $ctrl.blueprintType });
                      $ctrl.close({ $value: process });
                    }
                  });
                }
              } else {
                if ($ctrl.activeTab === 'existingDocument') {
                  saveFile(process);
                } else {
                  $ctrl.generatingSuggestedStep = false;
                  trackEvent({ label: 'BLUEPRINT CREATED', blueprintId: process.id, processName: process.title, type: $ctrl.blueprintType });
                  $ctrl.close({ $value: process });
                }
              }
            })
          }

          /**
           * @function
           * @name cancelModal
           * @description Callback function for cancel the modal
           */
          function cancelModal() {
            $ctrl.dismiss({ $value: 'cancel' });
          }

          /**
           * @name trackEvent
           * @param {*} eventProps
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventProps) {
            var defaultProps = { category: Helper.eventTrackingCategories['blueprint'] };
            angular.extend(defaultProps, eventProps);
            $analytics.eventTrack('Click', defaultProps);
          }

          function selectBlueprintType() {
            $timeout(function () {
              switch ($ctrl.blueprintType) {
                case 'procedure':
                  $ctrl.playGuideVideoLink = $sce.trustAsResourceUrl(_.toString('https://player.vimeo.com/video/721175142?h=0ebc34d280&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'));
                  break;
                case 'form':
                  $ctrl.playGuideVideoLink = $sce.trustAsResourceUrl(_.toString('https://player.vimeo.com/video/721177153?h=e27580418f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'));
                  break;
                case 'document':
                  $ctrl.playGuideVideoLink = $sce.trustAsResourceUrl(_.toString('https://player.vimeo.com/video/729562179?h=9e1e959b08&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'));
                  break;
              }
            }, 0);
          }

          function getSProbableStepType(step) {
            var type;
            if (step.task_type && step.task_type.includes('approval')) {
              type = 'approval';
            } else if (step.task_type && step.task_type.includes('expiring')) {
              if (step.task_type.includes('email')) {
                type = 'expiring_email';
              } else {
                type = 'expiring';
              }
            } else if (step.task_type && step.task_type.includes('email')) {
              type = 'email';
            } else {
              type = 'task';
            }
            return type;
          }

          function extractTextCompletionDataContent(data) {
            var content = Helper.lowercaseKeys(JSON.parse(_.get(data, 'content[0].text', '').replace('```json', '').replace('```', '').replace(/\\+\b/g, '\\\\') || '{}'));
            for (var key in content) {
              var stepKeys = Object.keys(content[key]);
              for (var i = 0; i < stepKeys.length; i++) {
                var type = getSProbableStepType(content[key][stepKeys[i]]);
                content[key][i].task_type = type;
              }
            }
            return Helper.lowercaseKeys(content);
          }

          function generateTextCompletion() {
            $ctrl.onSaving = true;
            $ctrl.suggestedStages = [];
            $ctrl.unmappedSteps = [];
            openAIService.generateChatCompletion({
              org_id: $rootScope.identity.default_organization.id,
              email: $rootScope.identity.email,
              type: $ctrl.selectedBlueprintType.value
            }, {
              process_name: $ctrl.processTitle,
              company_type: $ctrl.companyTypeGenerated,
              language: $ctrl.selectedLanguage.value
            }).then(function (res) {
              try {
                if ($ctrl.selectedBlueprintType.value === 'procedure') {
                  var content = extractTextCompletionDataContent(res.data);
                  var data = getStageStepInfos(content);
                  $ctrl.suggestedStages = data.stages;
                  $ctrl.unmappedSteps = data.unmappedSteps;
                  if ($ctrl.suggestedStages.length === 1) {
                    $ctrl.unmappedSteps = _.concat($ctrl.unmappedSteps, $ctrl.suggestedStages[0].steps);
                    $ctrl.suggestedStages = [];
                  }
                } else {
                  $ctrl.documentSummary = getDocumentSummary(_.get(res.data, 'content[0].text', ''));
                }
                $ctrl.percentage = parseInt($ctrl.percentage) + 50;
                $ctrl.onSaving = false;
              } catch (err) {
                generateTextCompletion();
              }
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          function getDocumentSummary(content) {
            return content.split('<body>')[1].split('</body>')[0];
          }

          function getStageStepInfos(content) {
            var stages = [], unmappedSteps = [], startIndex = 1, keys = Object.keys(content);
            for (var i = 0; i < keys.length; i++) {
              if (keys[i].toLowerCase() === 'uncategorized steps' || keys[i].toLowerCase() === 'uncategorize steps') {
                unmappedSteps = _.map(content[keys[i]], function (value) {
                  return value;
                });
              } else {
                var isStageExist = _.findIndex(stages, { title: keys[i] }) > -1;
                if (!isStageExist) {
                  var stage = {
                    title: ProcessService.capitalizeFirstLetterOfEachWord(keys[i].substring(0, 250).toLowerCase()),
                    startIndex: startIndex,
                    steps: _.map(content[keys[i]], function (value) {
                      return value;
                    }),
                    position: stages.length
                  };
                  stages.push(stage);
                  startIndex += stage.steps.length;
                }
              }
            }
            return { stages: stages, unmappedSteps: unmappedSteps };
          }

          function removeAttribute(step, attributeName) {
            step[attributeName] = void 0;
          }

          function deleteSuggestedStep(stage, stepData) {
            if (stage) {
              stage.steps = _.filter(stage.steps, function (step) {
                return step.step_name !== stepData.step_name;
              });
            } else {
              $ctrl.unmappedSteps = _.filter($ctrl.unmappedSteps, function (step) {
                return step.step_name !== stepData.step_name;
              });
            }
          }

          function deleteSuggestedStepDoc(stage, stepData) {
            if (stage) {
              stage.steps = _.filter(stage.steps, function (step) {
                return step.step_name !== stepData.step_name;
              });
            } else {
              $ctrl.unmappedStepsDoc = _.filter($ctrl.unmappedStepsDoc, function (step) {
                return step.step_name !== stepData.step_name;
              });
            }
          }

          function deleteSuggestedMilestone(stage) {
            $ctrl.unmappedSteps = _.concat($ctrl.unmappedSteps, stage.steps);
            $ctrl.suggestedStages = _.filter($ctrl.suggestedStages, function (suggestedStage) {
              return suggestedStage.title !== stage.title;
            });
          }

          function deleteSuggestedMilestoneDoc(stage) {
            $ctrl.unmappedStepsDoc = _.concat($ctrl.unmappedStepsDoc, stage.steps);
            $ctrl.suggestedStagesDoc = _.filter($ctrl.suggestedStagesDoc, function (suggestedStage) {
              return suggestedStage.title !== stage.title;
            });
          }

          function getStepType(step) {
            return _.find($ctrl.stepTypes, { value: step.task_type.toLowerCase() }) || 'task';
          }

          function stepTypeDDToggle(e, step) {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            $ctrl.stepEditTypeOpen[step.step_name] = !$ctrl.stepEditTypeOpen[step.step_name];
          }

          function onStepTypeSelected(step, stepType) {
            step.task_type = stepType.value;
          }

          //Get industries list
          function getIndustries() {
            OrganizationsService.getIndustries().then(function (response) {
              if (!_.isUndefined(response)) {
                $ctrl.industries = response;
              }
            });
          }

          function addStep(stage) {
            if (!stage) {
              $ctrl.newStepInStage.unmappedStep = {
                step_name: '',
                task_type: "task"
              }
            } else {
              $ctrl.newStepInStage[stage.title] = {
                section: stage.title,
                step_name: '',
                task_type: "task"
              }
            }
          }

          function saveStepInStage(stage) {
            if (stage) {
              stage.steps.push($ctrl.newStepInStage[stage.title]);
              $ctrl.newStepInStage[stage.title] = void 0;
            } else {
              $ctrl.unmappedSteps.push($ctrl.newStepInStage.unmappedStep);
              $ctrl.newStepInStage.unmappedStep = void 0;
            }
          }

          function cancelAddNewStepInStage(stage) {
            if (stage) {
              $ctrl.newStepInStage[stage.title] = void 0;
            } else {
              $ctrl.newStepInStage.unmappedStep = void 0;
            }
          }

          function getStepsLength(steps) {
            return steps.length;
          }

          function uploadFile(file) {
            if (!file || !file.length) {
              return;
            }
            $ctrl.suggestedStagesDoc = [];
            $ctrl.unmappedStepsDoc = [];
            $ctrl.processDocument = file[0];
            var name = $ctrl.processDocument.name, ext = name.toLowerCase().substring(name.lastIndexOf('.') + 1);
            if (ext === 'pdf') {
              $ctrl.mediaType = 'pdf';
              reader.readAsDataURL(file[0]);
            } else {
              $ctrl.mediaType = null;
              var docToText = new DocToText();
              docToText.extractToText($ctrl.processDocument, ext)
                .then(function (text) {
                  $ctrl.documentText = text;
                })
            }
          }

          function generateProcedureFromDocument() {
            $ctrl.onSaving = true;
            $ctrl.suggestedStagesDoc = [];
            $ctrl.unmappedStepsDoc = [];
            openAIService.generateDocChatCompletion({
              org_id: $rootScope.identity.default_organization.id,
              email: $rootScope.identity.email
            }, {
              document: $ctrl.documentText,
              mediaType: $ctrl.mediaType
            }).then(function (res) {
              try {
                var content = extractTextCompletionDataContent(res.data);
                $ctrl.suggestedStagesDoc = getStageStepInfos(content);
                var data = getStageStepInfos(content);
                $ctrl.suggestedStagesDoc = data.stages;
                $ctrl.unmappedStepsDoc = data.unmappedSteps;
                if ($ctrl.suggestedStagesDoc.length === 1) {
                  $ctrl.unmappedStepsDoc = _.concat($ctrl.unmappedStepsDoc, $ctrl.suggestedStagesDoc[0].steps);
                  $ctrl.suggestedStagesDoc = [];
                }
                $ctrl.percentage = parseInt($ctrl.percentage) + 50;
                $ctrl.onSaving = false;
              } catch (err) {
                generateProcedureFromDocument();
              }
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          function openFileUpload() {
            if ($ctrl.generatingSuggestedStep) {
              return;
            }
            $('#sop-file').trigger('click');
          }

          function saveFile(process) {
            var data = {
              name: $ctrl.processDocument,
              uploaded_from: 'procedure_bp_config',
              subject_type: 'Checklist',
              subject_id: process.id,
              source: 'local'
            };
            Upload.upload({
              url: ENV_CONFIG.API_HOST + '/organizations/' + _.get($rootScope.identity, 'organizations.id') + '/file',
              data: data
            }).then(function (resp) {
              $ctrl.generatingSuggestedStep = false;
              trackEvent({ label: 'BLUEPRINT CREATED', blueprintId: process.id, processName: process.title, type: $ctrl.blueprintType });
              $ctrl.close({ $value: newProcess });
            });
          }

          /**
           * Modal close prevent handler
           */
          onModalClosePreventHandler = $scope.$on('modal.closing', function (event, reason) {
            if ($ctrl.processForm.$dirty && (reason === MODAL_CLOSE_EVENTS.OUTSIDECLICK || reason === MODAL_CLOSE_EVENTS.ESCAPEPRESS)) {
              event.preventDefault();
            }
          });

          folderSelectedEventHandler = $rootScope.$on('FOLDER:SELECTED', function (e, data) {
            if ($ctrl.isOpenPopOver) {
              $ctrl.selectFolder = data.folder;
            }
            $ctrl.isOpenPopOver = false;
          });
        }
    });
})();