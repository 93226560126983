/**
 * @ngdoc Component
 * @name tallyfy.steps.component.createField
 * @module tallyfy.steps
 *
 * @description
 * A component to manage createField
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('createField', {
      bindings: {
        process: '<',
        field: '=',
        fieldType: '@',
        onDelete: '&',
        onDeleteOption: '&',
        onDeleteColumn: '&',
        step: '<?',
        onFieldUpdate: '&',
        isForPrerun: '<?',
        onFieldDiscard: '&',
        onSaving: '<',
        allFields: '<',
        isOneOfTaskForms: '<',
        koFormField: '<?',
        disableAutoFocus: '<',
        emitControlEvent: '<',
        disableFieldBlur: '<'
      },
      require: {
        stepsCtrl: '?^steps'
      },
      templateUrl: 'app/modules/steps/capture/fields/create-field.html',
      controller:
        /*@ngInject*/
        function (_, StepService, FieldService, Helper, blockUI, $scope, $rootScope, Growl, $timeout, TFY_EVENTS, DESCRIPTIONSIZE, $filter, PLANS, AuthPlan, CompactTaskService, FORM_FIELD, ProcessService) {
          var $ctrl = this,
            unregisterCapturesChangedEventHandler,
            growl = new Growl(),
            multipleChoice = ['radio', 'dropdown', 'multiselect', 'checkbox', 'select'],
            oldField,
            blockUI,
            last_updated,
            firstSort = true,
            onClickDelete,
            unsavedOptions,
            unsavedColumns,
            onChangeFieldWatcherHandler;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.isAliasOpen = false;
          $ctrl.isGuidanceOpen = false;
          $ctrl.isAdvancedOpen = false;
          $ctrl.isDraggingOption = {};
          $ctrl.isBlurPrevented = false;
          $ctrl.optionsSortableTableColumns = {
            handle: '.move-option',
            axis: 'y',
            stop: sortColumnsHandler,
            start: startColumnsHandler
          };

          $ctrl.optionsSortableOptions = {
            handle: '.move-option',
            axis: 'y',
            stop: sortHandler,
            start: startHandler
          };

          /**
           * public methods
           */
          $ctrl.deleteField = deleteField;
          $ctrl.newOption = newOption;
          $ctrl.deleteOption = deleteOption;
          $ctrl.deleteColumn = deleteColumn;
          $ctrl.onFocus = onFocus;
          $ctrl.saveCapture = saveCapture;
          $ctrl.discardCapture = discardCapture;
          $ctrl.toggleAliasRow = toggleAliasRow;
          $ctrl.toggleGuidanceRow = toggleGuidanceRow;
          $ctrl.isMultipleWithCheckbox = isMultipleWithCheckbox;
          $ctrl.isMultipleWithCheck = isMultipleWithCheck;
          $ctrl.isMandatory = isMandatory;
          $ctrl.isCollapsePane = isCollapsePane;
          $ctrl.isExpandPane = isExpandPane;
          $ctrl.copyToClipboard = Helper.copyToClipboard;
          $ctrl.saveFieldOnEnter = saveFieldOnEnter;
          $ctrl.toggleAdvancedRow = toggleAdvancedRow;
          $ctrl.toggleValidationRow = toggleValidationRow;
          $ctrl.toggleDefaultValueRow = toggleDefaultValueRow;
          $ctrl.onOptionBlur = onOptionBlur;
          $ctrl.onColumnBlur = onColumnBlur;
          $ctrl.newColumn = newColumn;
          $ctrl.onBlurField = onBlurField;
          $ctrl.getOptions = getOptions;
          $ctrl.getColumns = getColumns;
          $ctrl.onFieldEdit = onFieldEdit;
          $ctrl.minValueChanged = validateMinMaxValue;
          $ctrl.defaultValueFieldUpdated = defaultValueFieldUpdated;
          $ctrl.selectRadioOptions = selectRadioOptions;
          $ctrl.checkCreatedField = checkCreatedField;
          $ctrl.onChangeDefaultValue = onChangeDefaultValue;
          $ctrl.checkSuffixValidation = checkSuffixValidation;
          $ctrl.checkPrefixValidation = checkPrefixValidation;
          $ctrl.moveFieldOneStepToAnotherStep = moveFieldOneStepToAnotherStep;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            if ($ctrl.field.field_type === "multiselect") {
              prepareMultiSelectRadioOptions();
            }
            $ctrl.tempId = Helper.getId();
            $ctrl.maxFormFieldTitleLength = DESCRIPTIONSIZE.maxFormFieldTitleLength;
            if ($ctrl.isMultipleChoice) {
              prepareNewOptions();
            } else if ($ctrl.fieldType === 'table') {
              prepareNewTableColumns();
            } else if ($ctrl.fieldType === 'text') {
              validationConfig();
            }    
            $ctrl.defaultField = FieldService.getFieldByType($ctrl.fieldType);
            oldField = angular.copy($ctrl.field);
            blockUI = blockUI.instances.get('fieldView_' + $ctrl.field.id);
            $ctrl.panelId = $scope.$id;
            if (!$ctrl.field.isNew && $ctrl.field.columns) {
              $ctrl.field.columns = $ctrl.field.columns.length > 0 ? $ctrl.field.columns : null;
            }
            var frConfig = {
              allowEdit: $ctrl.haveAuthority,
              placeholder: $filter('translate')('steps.captures.fieldItem.guidance.placeholder'),
              currentUser: $rootScope.identity,
              enableFullscreen: true
            };
            if ($ctrl.emitControlEvent) {
              frConfig.contentChangedCallback = function (e) {
                onBlurField();
              };
            } else {
              frConfig.qAutoSave = {
                callback: onBlurField,
                debounce: 800
              };
            }
            $ctrl.froalaOptions = Helper.getFroalaOptions(frConfig, true);
            $ctrl.froalaOptions.autofocus = false;
            $ctrl.froalaOptions.entities = '';
            $ctrl.froalaOptions.variableValue = $ctrl.insertVariablesForm;
            $ctrl.froalaOptions.isFreePlan = AuthPlan.getCurrentPlanCode() === PLANS.FREE;
            $ctrl.froalaOptions.upgradeModal = upgradeModal;
            $ctrl.froalaOptions.enter = FroalaEditor.ENTER_BR;
            $ctrl.froalaOptions.transitionOnShow = true;
            $ctrl.froalaOptions.resizeable = true;
            if ($ctrl.field.field_type !== 'table' && $ctrl.field.field_type !== 'file' && $ctrl.field.default_value_enabled) {
              $ctrl.toggleDefaultValueRow();
            }
            $ctrl.stepsList = _.filter(_.get($ctrl.process, 'steps.data'), function (step) {
              return step.id !== _.get($ctrl.step, 'id');
            });
          }
          
          function validationConfig() {
            $ctrl.shortTextValidations = FieldService.getShortTextValidations();
            if ($ctrl.field.field_validation && $ctrl.field.field_validation.length) {
              $ctrl.validationType = $ctrl.field.field_validation[0];
              if ($ctrl.field.field_validation.length > 1) {
                $ctrl.enforceMinMaxLength = true;
                $ctrl.validationMin = parseInt(_.get($ctrl.field, 'field_validation[1]', '').split(':')[1] || 1);
                $ctrl.validationMax = parseInt(_.get($ctrl.field, 'field_validation[2]', '').split(':')[1] || 1);
              } else {
                $ctrl.enforceMinMaxLength = false;
                $ctrl.validationMin = 1;
                $ctrl.validationMax = 1;
              }
            } else {
              $ctrl.validationType = '';
              $ctrl.enforceMinMaxLength = false;
              $ctrl.validationMin = 1;
              $ctrl.validationMax = 1;
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
            if (bindings.fieldType && bindings.fieldType.isFirstChange()) {
              var fieldType = bindings.fieldType.currentValue.toLowerCase();
              $ctrl.isMultipleChoice = _.indexOf(multipleChoice, fieldType) >= 0;
            }
            if (bindings.onSaving) {
              if (bindings.onSaving.currentValue) {
                $ctrl.formEdited = true;
              } else {
                oldField = angular.copy($ctrl.field);
              }
            }
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unregisterCapturesChangedEventHandler();
            onChangeFieldWatcherHandler();
          }

          /**
           * @function
           * @name onChangeDefaultValue
           * @description Toggle set default choice checklist value update
           */
          function onChangeDefaultValue() {
            if (!$ctrl.isForPrerun) {
              updateCapture($ctrl.field, $ctrl.form);
            } else {
              if ($ctrl.field.default_value_enabled) {
                if ($ctrl.field.field_type !== 'date') {
                  var selected  = _.filter($ctrl.field.options, { selected: true });
                  if (selected.length > 0) {
                    $ctrl.field.default_value = selected;
                  }
                } else {
                  if (!$ctrl.field.default_value) {
                    $ctrl.field.default_value = 0;
                  }
                }
              }
              
              var isDefaultValDefined = ($ctrl.field.field_type === 'date') ? ($ctrl.field.default_value >= 0) : ($ctrl.field.default_value.length > 0);
              
              if (!$ctrl.field.default_value_enabled || ($ctrl.field.default_value_enabled && isDefaultValDefined)) {
                var index = _.findIndex($ctrl.process.prerun, {
                 'id': $ctrl.field.id
                });
                $ctrl.process.prerun[index] = $ctrl.field;
                if (!_.get($ctrl.process, 'auto_naming')) {
                  $ctrl.process.allow_launcher_change_name = null;
                }
                ProcessService.update({
                  id: $ctrl.process.id
                 }, $ctrl.process).then(function () {
                   Helper.showChangesSavedGrowl();
                 });
              }
            }
          }

          /**
           * @function
           * @name prepareMultiSelectRadioOptions
           * @description Prepare Radio select Options
           */
          function prepareMultiSelectRadioOptions() {
            if (_.get($ctrl.field, 'settings.must_all_checked', false)) {
              $ctrl.fieldRequired = 'all';
            } else if (_.get($ctrl.field, 'required', false)) {
              $ctrl.fieldRequired = 'true';
            } else {
              $ctrl.fieldRequired = 'false';
            }
          }

          /**
           * @function
           * @name newOption
           * @description Add new option of a field
           */
          function newOption(isRequired, event) {
            if (event) {
              event.stopPropagation();
            }

            var max = _.maxBy($ctrl.getOptions(), 'id');
            var option = {
              id: max && max.id ? max.id + 1 : 1,
              text: '',
              value: '',
              isNew: true,
              required: isRequired
            };

            if (!_.isArray(unsavedOptions)) {
              unsavedOptions = [];
            }

            unsavedOptions.push(option);
          }

          function selectRadioOptions() {
            if ($ctrl.fieldRequired === 'all') {
              _.set($ctrl.field, 'settings', { "must_all_checked": true });
              _.set($ctrl.field, 'required', true);
            } else {
              _.set($ctrl.field, 'settings', { "must_all_checked": false });
              if ($ctrl.fieldRequired === 'true') {
                _.set($ctrl.field, 'required', true);
              } else {
                _.set($ctrl.field, 'required', false);
              }
            }
            onFieldEdit();
          }

          function checkSuffixValidation(field) {
            $ctrl.suffixInValid = (field.suffix ? (field.suffix.length >= 5 && field.suffix.length <= 15 ? false : true) : false);
          }
          
          function checkPrefixValidation(field) {
            $ctrl.prefixInValid = (field.prefix ? (field.prefix.length <= 3 ? false : true) : false);
          }

          function newColumn(isRequired, event) {
            if (event) {
              event.stopPropagation();
            }

            var max = _.maxBy($ctrl.getColumns(), 'id');
            var column = {
              id: max && max.id ? max.id + 1 : 1,
              text: '',
              value: '',
              isNew: true,
              required: isRequired
            };

            if (!_.isArray(unsavedColumns)) {
              unsavedColumns = [];
            }

            unsavedColumns.push(column);
          }

          /**
           * @function
           * @name deleteOption
           * @description Delete option of a field
           */
          function deleteOption(option, $event, form) {
            setFormDirty(form);
            var unsavedOptionIndex = _.findIndex(unsavedOptions, { id: option.id });
            if (unsavedOptionIndex > -1) {
              unsavedOptions.splice(unsavedOptionIndex, 1);
            } else {
              $ctrl.field.options = $ctrl.field.options || [];
              for (var i = 0; i < $ctrl.field.options.length; i++) {
                if ($ctrl.field.options[i].id === option.id) {
                  $ctrl.onDeleteOption({
                    field: $ctrl.field,
                    option: option,
                    form: $ctrl.form
                  });
                  break;
                }
              }
              onFocus($event);
              saveCapture($ctrl.field, form, $event);
            }
          }

          function deleteColumn(column, $event, form) {
            setFormDirty(form);
            var unsavedColIndex = _.findIndex(unsavedColumns, { id: column.id });
            if (unsavedColIndex > -1) {
              unsavedColumns.splice(unsavedColIndex, 1);
            } else {
              $ctrl.onDeleteColumn({
                field: $ctrl.field,
                column: column
              });
              onFocus($event);
              saveCapture($ctrl.field, form, $event);
            }
          }

          /**
           * @function
           * @name setFormDirty
           * @description Make the form dirty
           */
          function setFormDirty(form) {
            form.$setDirty();
            if (_.get($ctrl.stepsCtrl, 'selectedStep', undefined)) {
              $ctrl.stepsCtrl.selectedStep.$dirty = true;
            }
          }

          /**
           * @function
           * @name onFocus
           * @description Stop propagation on focus of any item
           */
          function onFocus(event, optionId) {
            event.preventDefault();
            event.stopPropagation();
            if (optionId && event.target.id === 'option_' + optionId) {
              $ctrl.isBlurPrevented = true;
              $timeout(function () {
                $ctrl.isBlurPrevented = false;
              }, 500);
            }
          }

          /**
           * @function
           * @name saveCaptureData
           * @description Save Capture data
           */
          function saveCaptureData(field, form) {
            if (!field.options) {
              field.options = [];
            }
            if ($ctrl.fieldType === 'radio' || $ctrl.fieldType === 'dropdown' || $ctrl.fieldType === 'multiselect') {
              for (var i = 0; i < field.options.length; i++) {
                if (Helper.isObjectEmpty(field.options[i].text)) {
                  return;
                }
              }
            }
            $ctrl.field.isFFSave = true;
            if (field.field_type !== 'table' && field.field_type !== 'file') {
              if (!field.default_value_enabled) {
                field.default_value = null;
                field.default_value_enabled = false;
              } else {
                if (!field.default_value && field.field_type !== 'date') {
                  return;
                }
                var isHasEmptyRequiredFields = $ctrl.field.default_value_enabled && !$ctrl.field.default_value,
                  isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded([field]),
                  validationInfo = CompactTaskService.getShortTextValidationInfo(field);
                if ((isHasExceededTextChars || isHasEmptyRequiredFields || (validationInfo && !validationInfo.isValid)) && (_.get($ctrl.task, 'status') === 'completed' || $ctrl.iHaveCompleted)) {
                  return;
                }
              }
            } else {
              field.default_value = void 0;
              field.default_value_enabled = void 0;
            }
            angular.extend(oldField, $ctrl.field);
            if ($ctrl.isMultipleChoice || $ctrl.field.field_type === 'table') {
              if ($ctrl.field.field_type === 'table') {
                field.options = prepareOptions(field.options);
              }
              field.columns = prepareColumns(field.columns);
              removeTempOptionProperties(field);
              removeTempColumnsProperties(field);
            }
            if (field.field_type !== 'table') {
              delete field.columns;
            }
            if (!$ctrl.isForPrerun && !$ctrl.isOneOfTaskForms) {
              field.isNew ? createCapture(field, form) : updateCapture(field, form);
            } else {
              blockUI.start();
              $ctrl.onFieldUpdate({ field: $ctrl.field, form: $ctrl.form, defaultValue: true });
              $timeout(function() {
                blockUI.stop();
              }, 500);
            }
          }

          /**
           * @function
           * @name saveCapture
           * @description Save capture field
           * @param {Object} field
           * @param {Object} form
           * @param {event} event
           */
          function saveCapture(field, form, event) {
            if($ctrl.suffixInValid || $ctrl.prefixInValid) {
              return;
            }
            $ctrl.preCreatedField = _.filter($ctrl.process.prerun, function (step) {
              return step.label === field.label;
            });
            if ($ctrl.preCreatedField.length >= 2) {
              $ctrl.alreadyExistsvalidationErrors = true;
              $ctrl.form.$submitted = true;
              return;
            } else {
              $ctrl.alreadyExistsvalidationErrors = false;
            }
            if ($ctrl.isBlurPrevented || onClickDelete) {
              onClickDelete = false;
              return;
            }
            if (field.isNew && (form && !form.$dirty) && event) {
              discardCapture(form, field, event);
              return;
            }
            if (event) {
              event.stopPropagation();
            }
            if (((form && form.$pristine) && !field.isNew) || ((form && form.$pristine) && !$ctrl.onSaving)) {
              return;
            }
            $timeout(function () {
              if (form) {
                form.$setSubmitted();
              }
            }, 0);
            if (!field.label || (form && form.$invalid) || $ctrl.onSaving) {
              return;
            }
            saveCaptureData(field, form);
          }

          /**
           * @ngdoc method
           * @param {field} field
           *
           * @description
           * remove temporary/helper option properties before saves
           */
          function removeTempOptionProperties(field) {
            _.each(field.options, function (option) {
              delete option.isNew;
              delete option.required;
            });
          }

          function removeTempColumnsProperties(field) {
            _.each(field.columns, function (column) {
              delete column.isNew;
              delete column.required;
            });
          }

          /**
           * event handler when last selected forms tab changed
           * @type {*|(function())}
           */
          unregisterCapturesChangedEventHandler = $rootScope.$on('STEP:CHANGED_SELECTED_FORM_TAB', function () {
            $ctrl.form.$setSubmitted();
          });

          /**
           * @function
           * @name discardCapture
           * @description Discard all changes made in field
           */
          function discardCapture(form, field, $event) {
            if ((form.$pristine && !field.isNew)) {
              return;
            }
            $ctrl.formEdited = false;
            if ($event) {
              $event.stopPropagation();
            }
            $ctrl.field.isNew ? $ctrl.onDelete({ field: $ctrl.field, form: $ctrl.form }) : $ctrl.onFieldDiscard({ field: $ctrl.field, form: $ctrl.form });
            angular.extend($ctrl.field, _.omit(oldField, ['isExpanded']));
            oldField = angular.copy($ctrl.field);
            setFormPristine(form);
            delete $ctrl.field.isFFSave;
            delete $ctrl.field.isNew;
          }

          /**
           * @function
           * @name toggleAliasRow
           * @description Toggle open/close the aliad row
           */
          function toggleAliasRow($event) {
            if ($event.which === 13 || $event.which === 32) {
              return;
            }
            $ctrl.isAliasOpen = !$ctrl.isAliasOpen;
            $event.stopPropagation();
          }

          /**
           * @function
           * @name toggleGuidanceRow
           * @description Toggle open/close the guidance row
           */
          function toggleGuidanceRow($event) {
            if ($event.which === 13 || $event.which === 32) {
              return;
            }
            $ctrl.isGuidanceOpen = !$ctrl.isGuidanceOpen;
            $event.stopPropagation();
          }

          /**
           * @function
           * @name toggleAdvancedRow
           * @description Toggle open/close the advanced settings row
           */
          function toggleAdvancedRow($event) {
            if ($event.which === 13 || $event.which === 32) {
              return;
            }
            $ctrl.isAdvancedOpen = !$ctrl.isAdvancedOpen;
          }

          function toggleValidationRow($event) {
            if ($event.which === 13 || $event.which === 32) {
              return;
            }
            $ctrl.isValidationOpen = !$ctrl.isValidationOpen;
          }

          function toggleDefaultValueRow($event) {
            if ($event) {
              if ($event.which === 13 || $event.which === 32) {
                return;
              }
            }
            $ctrl.isDefaultValueOpen = !$ctrl.isDefaultValueOpen;
          }

          /**
           * @function
           * @public
           * @name isCollapsePane
           * @param {Object} pane
           * @param {Object} field
           * @param {Object} event
           * @param {Object} form
           * @description Collaps the vAccordion
           */
          function isCollapsePane(pane, field, event, form) {
            $ctrl.isBlurPrevented = false;
            if (pane.isExpanded()) {
              event.stopPropagation();
            }
            if (field.isExpanded && !form.$dirty) {
              saveCapture(field, form);
            }
            return collapse(field) ? angular.noop() : pane.collapse();
          }

          /**
           * @function
           * @public
           * @name isExpandPane
           * @param {Object} field
           * @param {Object} event
           * @description Expand the form field
           */
          function isExpandPane(field, event) {
            if (!Helper.checkAccessAuthority())
              return;
            if ($ctrl.form.$dirty && !field.isExpanded && event.which !== 32 && !$ctrl.isOneOfTaskForms) {
              $rootScope.$broadcast('SAVE:FORM_FIELD');
            }

            if (!field.isExpanded) {
              collapseAll();
              field.isExpanded = true;
            }
          }

          /**
           * @function
           * @public
           * @name collapseAll
           * @description Collapse the valid step captures
           */
          function collapseAll() {
            _.forEach($ctrl.allFields, function (field) {
              if (field.isExpanded && !FieldService.validateCaptures(field)) {
                field.isExpanded = false;
              }
            });
          }

          /**
           * @function
           * @private
           * @name collapse
           * @param {Object} field
           * @description Return true/false based on form field
           */
          function collapse(field) {
            if (FieldService.validateCaptures(field)) {
              $ctrl.form.$setSubmitted();
              return true;
            }
            return false;
          }

          /**
           * @function
           * @name isMultipleWithCheckbox
           * @description Check there will be checkbox in view
           * @returns {Boolean}
           */
          function isMultipleWithCheckbox() {
            var multipleItems = ['multiselect', 'checkbox'];
            return _.indexOf(multipleItems, $ctrl.fieldType) >= 0;
          }

          /**
           * @function
           * @name isMultipleWithCheck
           * @param {Object} type
           * @description Check there will radio in view
           * @returns {Boolean}
           */
          function isMultipleWithCheck(type) {
            return $ctrl.fieldType === type;
          }

          /**
           * @function
           * @name updateCapture
           * @param {Object} field
           * @param {Object} form
           * @description Update a capture
           * @returns {void}
           */
          function updateCapture(field, form) {
            if (!field.title) {
              field.title = angular.copy(field.label);
            }
            if (field.field_type !== 'table' && field.field_type !== 'file') {
              if (!field.default_value_enabled) {
                field.default_value = null;
                field.default_value_enabled = false;
              } else {
                var selected = _.filter($ctrl.field.options, { selected: true });
                if (selected.length > 0) {
                  field.default_value  = selected;
                }
                if (!field.default_value && field.field_type !== 'date') {
                  return;
                }
                if (field.default_value_enabled && !$ctrl.field.default_value && field.field_type === 'date') {
                  field.default_value = 0;
                }
                var isHasEmptyRequiredFields = $ctrl.field.default_value_enabled && !$ctrl.field.default_value,
                  isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded([field]),
                  validationInfo = CompactTaskService.getShortTextValidationInfo(field);
                if ((isHasExceededTextChars || isHasEmptyRequiredFields || (validationInfo && !validationInfo.isValid)) && (_.get($ctrl.task, 'status') === 'completed' || $ctrl.iHaveCompleted)) {
                  return;
                }
              }
            } else {
              field.default_value = void 0;
              field.default_value_enabled = void 0;
            }
            $ctrl.onSaving = true;
            $ctrl.formEdited = true;
            blockUI.start();
            StepService.updateStepField({
              id: $ctrl.step.id,
              action_id: field.id,
              checklist_id: $ctrl.process.id,
              skipNotFound: true
            }, field).then(function (response) {
              blockUI.stop();
              oldField = angular.copy(response.data);
              last_updated = _.get(response, 'data.last_updated');
              $ctrl.step.last_updated = last_updated;
              updateOriginalStepCapture(field.id);
              if ($ctrl.isMultipleChoice) {
                prepareNewOptions();
              }
              if (form) {
                setFormPristine(form);
              }
              $ctrl.onSaving = false;
              Helper.showChangesSavedGrowl();
              $rootScope.$emit('STEP:UPDATE_STEP_AND_PRERUN');
              $rootScope.$emit('STEP:UPDATED', $ctrl.step);
              $rootScope.$emit(TFY_EVENTS.STEP.UPDATE_DRAWER_STEP, { step: $ctrl.step, last_updated: last_updated });
            }, function () {
              blockUI.stop();
              $ctrl.onSaving = false;
            });
          }

          /**
           * @function
           * @name updateOriginalStepCapture
           * @private
           * @description Update the step capture
           * @param {integer} fieldId
           */
          function updateOriginalStepCapture(fieldId) {
            if ($ctrl.stepsCtrl) {
              var index = _.findIndex($ctrl.stepsCtrl.originalSteps, { id: $ctrl.step.id });
              if (index >= 0) {
                var fieldIndex = _.findIndex($ctrl.stepsCtrl.originalSteps[index].captures, { id: fieldId });
                if (fieldIndex >= 0) {
                  angular.extend($ctrl.stepsCtrl.originalSteps[index].captures[fieldIndex], angular.copy(oldField));
                }
              }
            }
          }

          /**
           * @function
           * @name createCapture
           * @description Update a capture
           * @param {Object} field
           * @param {*} form
           * @returns {void}
           */
          function createCapture(field, form) {
            $ctrl.onSaving = true;
            $ctrl.formEdited = true;
            if (field.isNew && !_.get(field, 'collect_time')) {
              field.collect_time = false;
            }
            delete field.isNew;
            if (field.field_type !== 'table' && field.field_type !== 'file') {
              if (!field.default_value_enabled) {
                field.default_value = null;
                field.default_value_enabled = false;
              } else {
                if (!field.default_value && field.field_type !== 'date') {
                  return;
                }
                var isHasEmptyRequiredFields = $ctrl.field.default_value_enabled && !$ctrl.field.default_value,
                  isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded([field]),
                  validationInfo = CompactTaskService.getShortTextValidationInfo(field);
                if ((isHasExceededTextChars || isHasEmptyRequiredFields || (validationInfo && !validationInfo.isValid)) && (_.get($ctrl.task, 'status') === 'completed' || $ctrl.iHaveCompleted)) {
                  return;
                }
              }
            } else {
              field.default_value = void 0;
              field.default_value_enabled = void 0;
            }
            StepService.createStepCapture({
              id: $ctrl.step.id,
              checklist_id: $ctrl.process.id,
              skipNotFound: true
            }, _.omit(field, ['isNew'])).then(function (response) {
              delete field.isNew;
              oldField = angular.copy(response.data);
              last_updated = _.get(response, 'data.last_updated');
              angular.extend($ctrl.field, response.data);
              var search = _.find($ctrl.step.captures, function (f) { return f.id === $ctrl.field.id; });
              if (!search) {
                $ctrl.step.captures.push($ctrl.field);
              }
              $ctrl.step.last_updated = last_updated;
              if ($ctrl.isMultipleChoice) {
                prepareNewOptions();
              }
              setFormPristine(form);
              $ctrl.onSaving = false;
              $rootScope.$emit('STEP:UPDATED', $ctrl.step);
              $rootScope.$emit(TFY_EVENTS.STEP.UPDATE_DRAWER_STEP, { step: $ctrl.step, last_updated: last_updated });
              Helper.showChangesSavedGrowl();
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @function
           * @name deleteField
           * @param {Object} field
           * @param {*} $event
           * @description Delete a field
           */
          function deleteField(field, $event) {
            if ($ctrl.koFormField) {
              if (_.get($ctrl.process, 'auto_naming') && $ctrl.process.default_process_name_format) {
                var title = angular.copy($ctrl.process.default_process_name_format);
                title.replace(/<[^>]+>/g, '').replace(/&nbsp;/gi, " ");
                var pos = title.indexOf('{{' + field.alias + '}}');
                if (pos > -1) {
                  StepService.openKickFieldInValidModal('kickoffform');
                } else {
                  removeField($event, field);
                }
              } else {
                removeField($event, field);
              }
            } else {
              removeField($event, field);
            }
          }

          function removeField($event, field) {
            onClickDelete = true;
            $ctrl.isBlurPrevented = true;
            if (!Helper.checkAccessAuthority())
              return;
            if ($event) {
              $event.stopPropagation();
            }
            var rule = [];
            if (field.isNew || $ctrl.isForPrerun || $ctrl.isOneOfTaskForms) {
              $ctrl.onDelete({ field: field, form: $ctrl.form });
              $timeout(function () {
                $ctrl.isBlurPrevented = false;
              }, 200);
            } else {
              rule = StepService.validateRule($ctrl.process, field);
              if (rule.length > 0) {
                StepService.openRuleModal(rule, 'Capture');
              } else {
                blockUI.start();
                StepService.deleteStepCapture({
                  id: $ctrl.step.id,
                  action_id: field.id,
                  checklist_id: $ctrl.process.id
                }).then(function () {
                  $ctrl.onDelete({
                    field: field,
                    isOld: true,
                    form: $ctrl.form
                  });
                  $ctrl.step.captures = _.filter($ctrl.step.captures, function (capture_item) {
                    return capture_item.id !== field.id;
                  });
                  $ctrl.isBlurPrevented = false;
                  $rootScope.$emit('STEP:UPDATED', $ctrl.step);
                  $rootScope.$emit(TFY_EVENTS.STEP.UPDATE_DRAWER_STEP, { step: $ctrl.step });
                  blockUI.stop();
                  Helper.showChangesSavedGrowl();
                }, function (error) {
                  growl.error(error.data.message, { referenceId: 'errorHandlerInterceptor' + $ctrl.step.id, disableIcons: true, disableCloseButton: true });
                  blockUI.stop();
                });
              }
            }
          }

          /**
           * @function
           * @name setFormPristine
           * @description Pristine the form
           * @returns {void}
           */
          function setFormPristine(form) {
            $ctrl.isGuidanceOpen = false;
            $ctrl.isAliasOpen = false;
            form ? form.$setPristine() : angular.noop();
            if ($ctrl.step) {
              $ctrl.step.$dirty = false;
            }
          }

          /**
           * @function
           * @name isMandatory
           * @description Check option is mandatory
           * @returns {Boolean}
           */
          function isMandatory(index) {
            if ($ctrl.field.options && !($ctrl.field.options.length > 2)) {
              if ((isMultipleWithCheck('dropdown') || isMultipleWithCheck('multiselect') || isMultipleWithCheck('radio')) && (index === 0 || index === 1)) {
                return true;
              }
            }
            if ($ctrl.field.columns && !($ctrl.field.columns.length > 1)) {
              if (isMultipleWithCheck('table') && (index === 0)) {
                return true;
              }
            }
            return false;
          }

          /**
           * @function
           * @name prepareOptions
           * @description Prepare field options
           * @param {Object} options
           * @returns {Object}
           */
          function prepareOptions(options) {
            return _.concat(_.remove(options, function (option) {
              return !_.isEmpty(_.get(option, 'text'));
            }), _.remove(unsavedOptions, function (option) {
              return !_.isEmpty(_.get(option, 'text'))
            }));
          }

          function prepareColumns(columns) {
            return _.concat(_.remove(columns, function (column) {
              return !_.isEmpty(_.get(column, 'label'));
            }), _.remove(unsavedColumns, function (column) {
              return !_.isEmpty(_.get(column, 'label'));
            }));
          }

          /**
           * @function
           * @name prepareNewOptions
           * @description Prepare options before loading the options form
           * @returns {void}
           */
          function prepareNewOptions() {
            if (_.isEmpty($ctrl.field.options)) {
              newOption(true);
              if ($ctrl.fieldType === 'radio' || $ctrl.fieldType === 'dropdown' || $ctrl.fieldType === 'multiselect') {
                newOption(true);
              }
            } else {
              prepareExistingOptions();
            }
          }

          /**
           * @function
           * @name prepareExistingOptions
           * @description Prepare  existing options
           * @returns {void}
           */
          function prepareExistingOptions() {
            $ctrl.field.options[0] = angular.extend($ctrl.field.options[0], {
              required: true
            });
            if ($ctrl.fieldType === 'radio' || $ctrl.fieldType === 'dropdown' || $ctrl.fieldType === 'multiselect') {
              $ctrl.field.options[1] = angular.extend($ctrl.field.options[1], {
                required: true
              });
            }
          }

          /**
           * @ngdoc method
           * @name saveFieldOnEnter
           * @param {Integer} field
           * @description Find the button id and trigger the "click" event
           */
          function saveFieldOnEnter(field) {
            if ($ctrl.onSaving || !$ctrl.form.$dirty) {
              return;
            }
            $timeout(function () {
              angular.element(document).find('#animatedButtonId_' + field.id).click();
            }, 0);
          }

          /**
           * @name startHandler
           * @param {*} e
           * @param {*} ui
           * @description handling when option dragging is started
           * @returns void
           */
          function startHandler(e, ui) {
            if (firstSort) {
              $(this).sortable("refreshPositions");
              firstSort = false;
            }
            ui.placeholder[0].style.visibility = "visible";
            ui.placeholder.height(ui.item.height());
          }

          function startColumnsHandler(e, ui) {
            if (firstSort) {
              $(this).sortable("refreshPositions");
              firstSort = false;
            }
            ui.placeholder[0].style.visibility = "visible";
            ui.placeholder.height(ui.item.height());
          }

          /**
           * @function
           * @name sortHandler
           * @param {Object} e
           * @param {Object} ui
           * @description A handler function to sort the options
           * @returns void
           */
          function sortHandler(e, ui) {
            if (ui.item.sortable.dropindex >= 0 && ui.item.sortable.dropindex < $ctrl.field.options.length) {
              setFormDirty($ctrl.form);
              saveCapture($ctrl.field, $ctrl.form, e);
            }
          }

          function sortColumnsHandler(e, ui) {
            if (ui.item.sortable.dropindex >= 0 && ui.item.sortable.dropindex < $ctrl.field.columns.length) {
              setFormDirty($ctrl.form);
              saveCapture($ctrl.field, $ctrl.form, e);
            }
          }

          /**
           * @function
           * @name onOptionBlur
           * @param {Object} e
           * @param {Object} form
           * @param {event} option
           * @description on option blur fired
           * @returns void
           */
          function onOptionBlur(e, form, option) {
            if (!_.isEmpty(option.text)) {
              if (option.isNew) {
                unsavedOptions = _.filter(unsavedOptions, function (unsavedOption) {
                  return unsavedOption.id !== option.id;
                });
                var checkOptions = _.filter($ctrl.field.options, function (checkOption) {
                  return checkOption.id === option.id;
                });
                if (!checkOptions.length) {
                  $ctrl.field.options.push(option);
                }
              }
              saveCapture($ctrl.field, form, e);
            }
          }

          function onColumnBlur(e, form, column) {
            $timeout(function () {
              if ($ctrl.field.label && !_.isEmpty(column.label)) {
                if (column.isNew) {
                  unsavedColumns = _.filter(unsavedColumns, function (unsavedColumn) {
                    return unsavedColumn.id !== column.id;
                  });
                  $ctrl.field.columns.push(column);
                }
                if (_.isEmpty(_.get($ctrl.field, 'columns[0].label', ''))){
                  return;
                }
                saveCapture($ctrl.field, form, e);
              }
            }, 50);
          }

          function prepareNewTableColumns() {
            if (_.isEmpty($ctrl.field.columns)) {
              $ctrl.field.columns = [];
              newColumn(true);
            }
          }

          function onFieldEdit(event) {
            if ($ctrl.koFormField) {
              $ctrl.checkKOFRequired = _.filter($ctrl.process.prerun, function (step) {
                return step.required === true;
              });
              $rootScope.$emit('INBOUND_EMAIL_UNAVAILABLE:UPDATE', {
                status: $ctrl.checkKOFRequired.length >= 1
              });
              if (_.get($ctrl.process, 'auto_naming') && $ctrl.process.default_process_name_format) {
                var pos, title = angular.copy($ctrl.process.default_process_name_format);
                title.replace(/<[^>]+>/g, '').replace(/&nbsp;/gi, " ");
                pos = title.indexOf('{{' + $ctrl.field.alias + '}}');
                if (pos > -1) {
                  $ctrl.field.required = true;
                  StepService.openKickFieldInValidModal('kickoffform');
                } else {
                  onBlurField(event);
                }
              } else {
                onBlurField(event);
              }
            } else {
              onBlurField(event);
            }
          }

          /**
           * @function
           * @name onBlurField
           * @param {event} event
           * @description on form field title input blur fired
           * @returns void
           */
          function onBlurField(event, input, fieldAttr) {
            if (event) {
              event.stopImmediatePropagation();
            }
            if (!$ctrl.field.isExpanded || ($ctrl.disableFieldBlur && input) || ((fieldAttr === 'field-label') && ($ctrl.field.label === _.get(oldField, 'label')))) {
              return;
            }
            $ctrl.field.field_validation = [];
            $ctrl.errorEnforceMinMaxRequired = false;
            if ($ctrl.validationType) {
              $ctrl.field.field_validation.push($ctrl.validationType);
              if ($ctrl.enforceMinMaxLength) {
                if (
                  $ctrl.validationMin < 0 || $ctrl.validationMax < 0 ||
                  _.isNull($ctrl.validationMin) || _.isUndefined($ctrl.validationMin) ||
                  _.isNull($ctrl.validationMax) || _.isUndefined($ctrl.validationMax)
                ) {
                  $ctrl.errorEnforceMinMaxRequired = true;
                } else {
                  $ctrl.field.field_validation.push('min:' + $ctrl.validationMin);
                  $ctrl.field.field_validation.push('max:' + $ctrl.validationMax);
                }
              } else {
                $ctrl.validationMin = 1;
                $ctrl.validationMax = 1;
              }
            } else {
              $ctrl.validationMin = 1;
              $ctrl.validationMax = 1;
              $ctrl.enforceMinMaxLength = false;
            }
            if ($ctrl.errorEnforceMinMaxRequired) {
              return;
            }
            validateMinMaxValue();
            $timeout(function () {
              saveCapture($ctrl.field, $ctrl.form, event);
            }, 500);
          }

          function getOptions() {
            if (Helper.isObjectEmpty(unsavedOptions)) {
              unsavedOptions = [];
            }
            return _.concat($ctrl.field.options, unsavedOptions);
          }

          function getColumns() {
            if (Helper.isObjectEmpty(unsavedColumns)) {
              unsavedColumns = [];
            }
            return _.concat($ctrl.field.columns, unsavedColumns);
          }

          /**
           * @ngdoc method
           * @name upgradeModal
           * @description Show upgrade modal
           * @returns {Object}
           */
          function upgradeModal() {
            AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'insertVariable');
          }

          function validateMinMaxValue() {
            if ($ctrl.enforceMinMaxLength) {
              $ctrl.validationMin = $ctrl.validationMin < 1 ? 1 : $ctrl.validationMin;
              $ctrl.validationMax = $ctrl.validationMax <= $ctrl.validationMin ? $ctrl.validationMin + 1 : $ctrl.validationMax;
            } else {
              $ctrl.validationMin = 1;
              $ctrl.validationMax = 1;
            }
          }

          function defaultValueFieldUpdated(field) {
            if (field.field_type === 'table' || field.field_type === 'file') {
              return;
            }
            if (field.field_type !== 'date') {
              $ctrl.field.default_value = field.default_value = FieldService.getFieldValue(field);
            } else {
              $ctrl.field.default_value = field.default_value;
            }
            var isHasEmptyRequiredFields = field.field_type !== 'date' ? $ctrl.field.default_value_enabled && !$ctrl.field.default_value : false,
              isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded([field]),
              validationInfo = CompactTaskService.getShortTextValidationInfo(field);
            if ((isHasExceededTextChars || isHasEmptyRequiredFields || (validationInfo && !validationInfo.isValid)) && !(_.get($ctrl.task, 'status') === 'completed' || $ctrl.iHaveCompleted)) {
              return;
            }
            saveCaptureData(field);
          }

          function checkCreatedField() {
            return ($ctrl.field.label === FORM_FIELD.EMAIL_BODY_FIELD || $ctrl.field.label === FORM_FIELD.EMAIL_ATTACHMENTS_FIELD);
          }

          function moveFieldOneStepToAnotherStep(step) {
            blockUI.start();
            var fieldparams = {
              target_step_id: step.id,
              position: 1
            };
            StepService.moveFieldOneStepToAnotherStep({
              org: $rootScope.identity.default_organization.id,
              checklist_id: $ctrl.process.id,
              step_id: $ctrl.step.id,
              capture_id: $ctrl.field.id
            }, fieldparams).then(function () {
              _.remove($ctrl.step.captures, { id: $ctrl.field.id });
              $rootScope.$emit('STEP:MOVE_CAPTURE', step.id, $ctrl.field);
              $rootScope.$emit('STEP:FIELD_CHANGED', { step: $ctrl.step });
              blockUI.stop();
              growl.info($filter('translate')('global.messages.movedSuccessfully'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }, function () {
              blockUI.stop();
            });
          }

          onChangeFieldWatcherHandler = $scope.$watch('$ctrl.field', function (newValue, oldValue) {
            if (newValue && oldValue && (newValue.id === oldValue.id)) {
              return;
            }
            if (newValue && oldValue && (newValue.field_type === "text")) {
              validationConfig();
            }
          }, true);
          //controller ends
        }
    });
})();
